import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function TeamSingle() {
  const [team, setTeam] = useState({});
  const singleTeam = useMatch(WEB_ROUTES.TEAM + "/:team");

  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "team"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get team
        axios
          .get(CONTENT_ROUTES.TEAM("?slug=" + singleTeam?.params?.team))
          .then((res) => {
            let teamResponse = res.data?.length ? res.data[0] : {};
            setTeam(teamResponse);

            //Get team media
            let media = [];

            res.data?.map((member, i) => {
              if (member?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(member?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setTeamMedia((prevState) => [...prevState, media[i]]);
                  });
              return member?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleTeam]);

  return (
    <>
      <Helmet>
        <title>{team?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(team?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 md:pb-8 pb-20 xl:px-0 px-8 text-white">
          <div className="container mx-auto ">
            <div className="mx-auto">
              <div className="w-full">
                <div className="w-full text-white">
                  <h1 className="text-4xl font-semibold pb-5">
                    {team?.title?.rendered}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-16 bg-white xl:px-0 px-8">
          <div className="container mx-auto ">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="col-span-1 md:order-1 order-2">
                <p
                  dangerouslySetInnerHTML={{
                    __html: team?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
                {team?.acf?.team_member_qualifications ? (
                  <>
                    <h2 className="wp-block-heading">Qualifications</h2>
                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: team?.acf?.team_member_qualifications,
                      }}
                    />
                  </>
                ) : null}

                {team?.acf?.team_member_area_of_expertise ? (
                  <>
                    <h2 className="wp-block-heading">Area of expertise</h2>
                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: team?.acf?.team_member_area_of_expertise,
                      }}
                    />
                  </>
                ) : null}

                {team?.acf?.team_member_boards ? (
                  <>
                    <h2 className="wp-block-heading">
                      Board memberships outside PRASA
                    </h2>
                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: team?.acf?.team_member_boards,
                      }}
                    />
                  </>
                ) : null}
              </div>
              <div className="col-span-1 xl:px-20 md:order-2 order-1 md:pb-0 pb-8">
                <div className="relative w-full">
                  <img
                    src="/images/prasa-icon-white.svg"
                    className="absolute z-20 lg:left-14 lg:-top-2 lg:w-24 lg:h-24 w-16 h-16 left-8 -top-1"
                    alt=""
                  />
                  <img
                    src={
                      teamMedia?.find(
                        (media) => media.id === team?.featured_media
                      )?.source_url
                    }
                    alt={team?.title?.rendered}
                    className="lg:w-[480px] w-[300px] lg:h-[480px] h-[300px] rounded-full object-cover lg:-mt-40 -mt-28"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
