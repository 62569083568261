import { useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import FormContact from "../components/form-contact";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Contact() {
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "contact-us"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className=" relative">
          <div className="absolute top-0 left-0 bg-[#46515e] w-1/4 h-full -z-10 lg:block hidden"></div>
          <div className="absolute top-0 right-0 bg-[#06a7e0] w-1/2 h-full -z-10 lg:block hidden"></div>
          <div className="xl:container xl:mx-auto">
            <div className="lg:flex">
              <article className="w-full xl:w-2/5 xl:mb-0 xl:pr-10 xl:pl-0 py-16 px-8 bg-[#46515e] text-white">
                <h1 className="pb-5 text-4xl font-semibold text-[#06a7e0]">
                  {page?.title?.rendered}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered,
                  }}
                />
              </article>
              <div className="relative w-full xl:w-3/5 bg-[#06a7e0] xl:p-16 px-8 md:pt-16 pt-10 pb-48">
                <h3 className="font-semibold">Email us now</h3>
                <GoogleReCaptchaProvider reCaptchaKey="6LfxvbEqAAAAAGiMjPcJX_IdwovFo9ekvH25f5dd">
                  <FormContact />
                </GoogleReCaptchaProvider>
                <div className="absolute z-20 xl:-bottom-40 xl:left-20 xl:w-80 w-60 h-auto opacity-55">
                  <img
                    src="images/prasa-icon-white.svg"
                    className="xl:w-80 w-60 h-auto"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="relative flex bg-no-repeat bg-[right_0px_center] bg-[length:auto_150%] 2xl:bg-[length:auto_200%]"
          style={{
            backgroundImage: `url(${
              pageMedia?.find((media) => media?.id === page?.featured_media)
                ?.source_url
            })`,
          }}
        >
          <div className="absolute top-0 left-0 bg-[#46515e] w-1/4 h-full -z-10 lg:block hidden"></div>

          <div className="lg:flex xl:container xl:mx-auto w-full">
            <div className="xl:w-2/5 lg:w-3/6 google_map">
              <div
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.google_map,
                }}
              />
            </div>
            <div className="relative xl:w-3/5 lg:w-3/6 "></div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
