import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Announcements() {
  const [announcements, setAnnouncements] = useState([]);
  const [page, setPage] = useState({});
  let { getMetaSnippet } = useUtilities();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  /* ANNOUNCEMENTS */
  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "announcements"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    //Get announcements
    axios
      .get(
        CONTENT_ROUTES.ANNOUNCEMENTS(
          "?order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setAnnouncements(res.data);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] text-white">
          <div className="container mx-auto">
            <div className="mx-auto md:flex">
              <div className="w-full py-16">
                <div className="relative xl:px-0 px-8">
                  <h1 className="text-4xl font-semibold text-white">
                    {page?.title?.rendered}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute top-0 left-0 bg-[#06a7e0] w-full h-40 -z-10"></div>
          <div className="container mx-auto xl:px-0 px-8">
            <div className="mx-auto md:flex">
              <article className="md:w-9/12 w-full md:p-16 px-8 py-16 bg-white">
                {announcements?.map((announcement, i) => (
                  <Fragment key={i}>
                    <h3 className="float-left text-[#06a7e0]">
                      {announcement?.title?.rendered}
                    </h3>
                    <span className="float-left text-xs bg-[#ceb596] rounded-full px-2 py-0.5 text-white mt-1 ml-1">
                      {new Date(announcement?.date)?.getDate() +
                        " " +
                        monthNames[new Date(announcement?.date)?.getMonth()] +
                        " " +
                        new Date(announcement?.date)?.getFullYear()}
                      {` `}
                    </span>
                    <div className="clear-both"></div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: announcement?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </Fragment>
                ))}
              </article>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
