import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function RailOperations() {
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "rail-operations"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:py-16 py-10 text-white">
          <div className="container mx-auto">
            <h1
              className="text-4xl font-semibold xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: page?.title?.rendered,
              }}
            />
          </div>
        </section>

        <section className="relative md:py-16 py-10">
          <div className="container mx-auto xl:px-0 px-8">
            <div
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered?.replace(
                  /<p.*?>/g,
                  "<p class='mb-5'>"
                ),
              }}
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
