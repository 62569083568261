import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function GallerySingle() {
  const [gallery, setGallery] = useState({});
  const singleGallery = useMatch(WEB_ROUTES.GALLERY + "/:gallery");
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [galleryMedia, setGalleryMedia] = useState([]);
  const [open, setOpen] = useState(false); // Lightbox open state
  const [currentImage, setCurrentImage] = useState(null); // Current image for lightbox
  const [slides, setSlides] = useState([]); // All images for lightbox
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    if (gallery?.content?.rendered) {
      // Select all figure elements with the `wp-block-image` class
      const images = document.querySelectorAll("figure.wp-block-image img");

      // Fetch dimensions dynamically for full-size images
      const fetchImageDimensions = (src) =>
        new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
          };
          img.src = src;
        });

      // Create slides array for the lightbox
      const prepareSlides = async () => {
        const lightboxSlides = await Promise.all(
          Array.from(images).map(async (img) => {
            const originalSrc = img.src.replace(/-\d+x\d+(?=\.\w+$)/, ""); // Remove the size suffix
            const dimensions = await fetchImageDimensions(originalSrc);
            return {
              src: originalSrc, // Use the full-size URL
              width: dimensions.width,
              height: dimensions.height,
              draggable: "false",
              onContextMenu: (e) => {
                e.preventDefault();
              },
              /* title: gallery?.title?.rendered,
              description:
                "&copy; PRASA. This image is intended exclusively for editorial use by PRASA and is not authorised for any commercial purposes.", */
            };
          })
        );
        setSlides(lightboxSlides);
      };

      prepareSlides();

      // Add click listeners
      images.forEach((image, index) => {
        image.classList.add("hover:cursor-pointer"); // Add hover class to image
        image.addEventListener("click", (event) => handleClick(event, index));
      });

      // Cleanup listeners
      return () => {
        images.forEach((image, index) => {
          image.removeEventListener("click", (event) =>
            handleClick(event, index)
          );
        });
      };
    }
  }, [gallery]);

  // Handle click event
  const handleClick = (event, index) => {
    setCurrentImage(index); // Set the current image index
    setOpen(true); // Open the lightbox
  };

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "gallery"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get gallery
        axios
          .get(
            CONTENT_ROUTES.GALLERY("?slug=" + singleGallery?.params?.gallery)
          )
          .then((res) => {
            let galleryResponse = res.data?.length ? res.data[0] : {};
            setGallery(galleryResponse);

            //Get gallery media
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_PAGE(galleryResponse?.id))
              .then((res) => {
                setGalleryMedia(res.data);
              });
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleGallery]);

  return (
    <>
      <Helmet>
        <title>{gallery?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(gallery?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        {Object.keys(gallery).length ? (
          <>
            <section className="bg-[#06a7e0] text-white">
              <div className="container mx-auto">
                <div className="mx-auto md:flex">
                  <div className="w-full py-16">
                    <div className="relative xl:px-0 px-8">
                      <span className="font-semibold text-[#46515e]">
                        Gallery
                      </span>
                      <h1 className="pb-5 pt-2 text-4xl font-semibold">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: gallery?.title?.rendered,
                          }}
                        />
                      </h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: page?.content?.rendered,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="relative xl:p-0 p-8">
              <div
                id="gallery-content"
                className="container mx-auto xl:py-16 py-10 "
                dangerouslySetInnerHTML={{
                  __html: gallery?.content?.rendered.replace(
                    "<img",
                    "<div style=\"position: 'absolute'; top: 0; left: 0; width: '100%'; height: '100%'; backgroundColor: 'rgba(255, 255, 255, 0)';zIndex: 1\" ></div><img"
                  ),
                }}
              />
            </section>
            {/* Lightbox */}
            <Lightbox
              plugins={[Captions]}
              slides={slides} // Provide all slides
              open={open} // Control visibility
              index={currentImage} // Start at the clicked image
              close={() => setOpen(false)} // Close lightbox
              styles={{
                container: {
                  background: "rgba(0, 0, 0, 0.8)", // Adjust the RGBA value for opacity
                },
              }}
              onBackdropClick={() => setOpen(false)} // Close when clicking the overlay
              render={{
                slideFooter: ({ slide }) => (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      &copy; PRASA. This image is intended exclusively for
                      editorial use by PRASA and is not authorised for any
                      commercial purposes.
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        zIndex: 1,
                      }}
                    />
                  </>
                ),
              }}
            />
          </>
        ) : null}
      </main>
      <Footer />
    </>
  );
}
