import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function ProjectsSingle() {
  const [project, setProject] = useState({});
  const singleProject = useMatch(WEB_ROUTES.PROJECTS + "/:project");
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [projectMedia, setProjectMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "projects"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get project
        axios
          .get(
            CONTENT_ROUTES.PROJECTS("?slug=" + singleProject?.params?.project)
          )
          .then((res) => {
            let projectResponse = res.data?.length ? res.data[0] : {};
            setProject(projectResponse);

            //Get project media
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_PAGE(projectResponse?.id))
              .then((res) => {
                setProjectMedia(res.data);
              });
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleProject]);

  return (
    <>
      <Helmet>
        <title>{project?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(project?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        {Object.keys(project).length ? (
          <>
            <section className="bg-[#06a7e0] text-white">
              <div className="container mx-auto">
                <div className="mx-auto md:flex">
                  <div className="w-full py-16">
                    <div className="relative xl:px-0 px-8">
                      {/* <span className="font-semibold text-[#46515e]">
                        {project?.acf?.project_status} project
                      </span> */}
                      <h1 className="pt-2 text-4xl font-semibold">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: project?.title?.rendered,
                          }}
                        />
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="relative">
              <div className="absolute top-0 left-0 bg-[#06a7e0] w-full h-40 -z-10"></div>
              <div className="container mx-auto">
                <div className="mx-auto md:flex">
                  <article className="xl:w-9/12 w-full pb-16 bg-white">
                    <img
                      src={
                        projectMedia?.find(
                          (media) => media?.id === project?.featured_media
                        )?.source_url
                      }
                      className={`mb-5 m-auto object-cover`}
                      alt=""
                    />
                    <div
                      className="xl:px-0 px-8 md:pt-0 pt-4"
                      dangerouslySetInnerHTML={{
                        __html: project?.content?.rendered?.replace(
                          /<p>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </article>
                </div>
              </div>
            </section>
          </>
        ) : null}
      </main>
      <Footer />
    </>
  );
}
