const CUSTOM_API_URL = `https://www.prasa.com/admin/wp-json/custom/v1`;
const CONTENT_API_URL = `https://www.prasa.com/admin/wp-json/wp/v2`;

export const CONTENT_ROUTES = {
  SERVICES: function (params) {
    return `${CONTENT_API_URL}/service/${params}`;
  },
  TEAM: function (params) {
    return `${CONTENT_API_URL}/team-member/${params}`;
  },
  BANNERS: function (params) {
    return `${CONTENT_API_URL}/banner/${params}`;
  },
  PROJECTS: function (params) {
    return `${CONTENT_API_URL}/project/${params}`;
  },
  ANNOUNCEMENTS: function (params) {
    return `${CONTENT_API_URL}/announcement/${params}`;
  },
  JOBS: function (params) {
    return `${CONTENT_API_URL}/job/${params}`;
  },
  REAL_ESTATE: function (type, params) {
    return `${CONTENT_API_URL}/${type}/${params}`;
  },
  DIVISIONS: function (params) {
    return `${CONTENT_API_URL}/division/${params}`;
  },
  PROCUREMENT: function (type, params) {
    return `${CONTENT_API_URL}/${type}/${params}`;
  },
  PUBLICATION: function (type, params) {
    return `${CONTENT_API_URL}/${type}/${params}`;
  },
  FACTS_SHEETS: function (params) {
    return `${CONTENT_API_URL}/facts-sheet/${params}`;
  },
  CORPORATE_PLANS: function (params) {
    return `${CONTENT_API_URL}/corporate-plan/${params}`;
  },
  GALLERY: function (params) {
    return `${CONTENT_API_URL}/gallery/${params}`;
  },
  MEDIA_BY_PAGE: function (id) {
    return `${CONTENT_API_URL}/media/?parent=${id}`;
  },
  MEDIA_BY_ID: function (id) {
    return `${CONTENT_API_URL}/media/${id}`;
  },
  POST_PAGE: function (type, slug) {
    return `${CONTENT_API_URL}${type}?slug=${slug}`;
  },
  POST_CATEGORY: function (category) {
    return `${CONTENT_API_URL}/categories?slug=${category}`;
  },
  POST_PAGE_BY_PARENT: function (type, parent_id) {
    return `${CONTENT_API_URL}${type}?parent=${parent_id}`;
  },
  SUBMIT_CONTACT_EMAIL: function () {
    return `${CUSTOM_API_URL}/send-contact-email`;
  },
  FLEET: {
    ALL: `${CONTENT_API_URL}/fleet?per_page=100`,
    CATEGORIES: `${CONTENT_API_URL}/fleet_categories`,
    BY_CATEGORY: function (category_id) {
      return `${CONTENT_API_URL}/fleet?fleet_categories=${category_id}&per_page=100`;
    },
  },
  BRANCHES: {
    CATEGORIES: `${CONTENT_API_URL}/branches_categories`,
    BY_CATEGORY: function (category_id) {
      return `${CONTENT_API_URL}/branches?branches_categories=${category_id}&per_page=100`;
    },
    BY_SLUG: function (slug) {
      return `${CONTENT_API_URL}/branches?slug=${slug}`;
    },
  },
};
