export default function RealEstateOpportunitiesItem({ item, itemMedia, i }) {
  return (
    <>
      <div
        className={`${
          i % 2 === 0 ? "bg-[#e0e0e2]" : "bg-white"
        } w-full py-10 px-10`}
      >
        <div className="mb-4">
          <div
            className="text-[#06a7e0] text-xl font-semibold "
            dangerouslySetInnerHTML={{
              __html: item?.acf?.ref_no + " - " + item?.title?.rendered,
            }}
          />
        </div>
        <div className="divide-x-0 md:divide-x divide-gray-300 md:flex space-x-0 md:space-x-2 ">
          <div
            className="md:w-1/6 w-full mb-2 md:mb-0 "
            dangerouslySetInnerHTML={{
              __html: item?.content?.rendered?.replace(
                /<p.*?>/g,
                "<p class='mb-5'>"
              ),
            }}
          />

          <div className="md:w-1/6 w-full mb-2 md:mb-0 p-0 md:pl-2">
            <p>
              <span className="font-semibold">Region:</span>
              <br />
              <span
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: item?.acf?.region,
                }}
              />
            </p>
          </div>

          <div className="md:w-1/6 w-full mb-2 md:mb-0 p-0 md:pl-2">
            <p>
              <span className="font-semibold">Category:</span>
              <br />
              <span
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: item?.acf?.category,
                }}
              />
            </p>
          </div>

          <div className="md:w-1/6 w-full mb-2 md:mb-0 p-0 md:pl-2">
            <p>
              <span className="font-semibold">Potential Usage:</span>
              <br />
              <span
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: item?.acf?.potential_usage,
                }}
              />
            </p>
          </div>

          <div className="md:w-1/6 w-full mb-2 md:mb-0 p-0 md:pl-2">
            <p>
              <span className="font-semibold">Briefing Details:</span>
              <br />
              <span
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: item?.acf?.briefings,
                }}
              />
            </p>
          </div>

          <div className="md:w-1/6 w-full mb-2 md:mb-0 p-0 md:pl-2">
            <p>
              <span className="font-semibold">Document Availability:</span>
              <br />
              <span
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: item?.acf?.document_availability,
                }}
              />
              {item?.acf?.opportunity_document && (
                <>
                  <br />(
                  <a
                    href={
                      itemMedia?.find(
                        (media) => media?.id === item?.acf?.opportunity_document
                      )?.source_url
                    }
                    className="text-[#06a7e0] text-md hover:underline hover:cursor-pointer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Document
                  </a>
                  )
                </>
              )}
            </p>
          </div>
        </div>

        <p className="mt-4">
          <span className="font-semibold">
            Closing Date/Submission details:
          </span>{" "}
          <span
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: item?.acf?.closing_datesubmission_details,
            }}
          />
        </p>

        <p>
          <span className="font-semibold">Contact Details:</span>{" "}
          <span
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: item?.acf?.contact_details,
            }}
          />
        </p>

        <p>
          <span className="font-semibold">Reference No:</span>{" "}
          <span
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: item?.acf?.ref_no,
            }}
          />
        </p>

        <div className="clear-both"></div>
      </div>
    </>
  );
}
