import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import DivisionsScroller from "../components/divisions-scroller";
import { WEB_ROUTES } from "../routes/web-routes";

export default function About() {
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [prasaLeadership, setPrasaLeadership] = useState([]);
  const [prasaLeadershipMedia, setPrasaLeadershipMedia] = useState([]);

  let { getMetaSnippet } = useUtilities();
  const [selectedMandate, setSelectedMandate] = useState("primary");
  const [aboutSecondaryMandate, setAboutSecondaryMandate] = useState({});

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "about"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get child pages
        axios
          .get(
            CONTENT_ROUTES.POST_PAGE_BY_PARENT(
              "/pages",
              pageResponse?.id + "&_embed"
            )
          )
          .then((res) => {
            let secondaryMandate = res.data.filter(
              (child) => child.slug === "prasas-secondary-mandate"
            );
            if (secondaryMandate?.length)
              setAboutSecondaryMandate(secondaryMandate[0]);

            let leadership = res.data.filter(
              (child) => child.slug === "prasa-leadership"
            );
            if (leadership.length) {
              setPrasaLeadership(leadership[0]);

              if (leadership[0]?.featured_media)
                axios
                  .get(
                    CONTENT_ROUTES.MEDIA_BY_ID(leadership[0]?.featured_media)
                  )
                  .then((res) => {
                    setPrasaLeadershipMedia((prevState) => [
                      ...prevState,
                      res.data,
                    ]);
                  });
            }
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="md:flex container mx-auto ">
            <div
              className="md:w-1/2 w-full bg-no-repeat md:bg-[left_0px_top_50%] bg-[left_50%_top_0] md:min-h-0 min-h-60"
              style={{
                backgroundImage: `url(${
                  pageMedia?.find((media) => media.id === page?.featured_media)
                    ?.source_url
                })`,
              }}
            ></div>
            <div className="lg:w-1/2 w-full xl:px-16 px-8 pb-8">
              <h1
                className="pb-4 text-3xl font-medium"
                dangerouslySetInnerHTML={{
                  __html: page?.title?.rendered
                    ?.replace(/<p.*?>*?<\/p>/g, "")
                    ?.replace(/<h2.*?>/g, ""),
                }}
              />
              <nav
                className="flex mb-4 w-full md:space-x-2 space-x-1 text-center items-stretch"
                aria-label="Tabs"
              >
                <div
                  onClick={() => {
                    setSelectedMandate("primary");
                  }}
                  className={`py-3 hover:cursor-pointer px-4 rounded-full hover:bg-[#166f97] md:text-base text-sm ${
                    selectedMandate === "primary"
                      ? `bg-[#166f97]`
                      : `bg-[#72bbe8]`
                  }`}
                >
                  PRASA's Primary Mandate
                </div>
                <div
                  onClick={() => {
                    setSelectedMandate("secondary");
                  }}
                  className={`py-3 hover:cursor-pointer px-4 rounded-full hover:bg-[#166f97] md:text-base text-sm ${
                    selectedMandate === "secondary"
                      ? `bg-[#166f97]`
                      : `bg-[#72bbe8]`
                  }`}
                >
                  PRASA's Secondary Mandate
                </div>
              </nav>

              {selectedMandate === "primary" ? (
                <div>
                  <h2
                    className="font-bold mb-3"
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered
                        ?.replace(/<p.*?>*?<\/p>/g, "")
                        ?.replace(/<h2.*?>/g, ""),
                    }}
                  />

                  <div
                    className="mb-8 "
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered
                        ?.replace(/<h2.*?>*?<\/h2>/g, "")
                        ?.replace(/<p>/g, "<p class='mb-4'>"),
                    }}
                  />
                </div>
              ) : null}

              {selectedMandate === "secondary" ? (
                <div>
                  <h2
                    className="font-bold mb-3"
                    dangerouslySetInnerHTML={{
                      __html: aboutSecondaryMandate?.title?.rendered,
                    }}
                  />
                  <div
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: aboutSecondaryMandate?.content?.rendered?.replace(
                        /<p>/g,
                        "<p className='mb-4'>"
                      ),
                    }}
                  />
                  <NavLink
                    to={WEB_ROUTES.DIVISION_PRASA_CRES}
                    className="flex text-[#25374f] mb-8"
                  >
                    <span>More about PRASA Cres</span>
                    <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
                  </NavLink>
                </div>
              ) : null}

              <div className="flex mb-10 space-x-6">
                <img
                  src="images/prasa-mission.svg"
                  class="w-auto h-16"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mb-0 font-semibold">Mission</h5>
                  <p className="text-base">
                    Provide safe, predictable, consistent, affordable and
                    quality commuter rail, long-haul passenger rail and
                    long-haul intercity bus services.
                  </p>
                </div>
              </div>

              <div className="flex mb-10 space-x-6">
                <img src="images/prasa-vision.svg" class="w-auto h-16" alt="" />

                <div className="w-full">
                  <h5 className="text-xl mb-0 font-semibold">Vision</h5>
                  <p className="text-base">
                    To be a recognised provider and manufacturer of safe and
                    reliable public passenger services and products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[#25374f] text-white text-center md:pb-8 pb-0 md:pt-20 pt-10 xl:px-16 px-8">
          <div className="container mx-auto">
            <h3 className="text-4xl font-medium ">Values</h3>
            <p>
              To be a recognised provider and manufacturer of safe and reliable
              public passenger services and products.
            </p>
            <div className="mt-10 mx-auto grid grid-cols-1 grid-rows-1 md:grid-cols-3 md:grid-rows-2 md:space-x-10">
              <div className="text-center mb-10">
                <img
                  src="images/icon-fairness-and-integrity.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Fairness and Integrity
                  </h5>
                  <p className="mb-4 text-base">
                    We treat our customers and our colleagues the same as we
                    would like to be treated.
                  </p>
                </div>
              </div>

              <div className="text-center mb-10">
                <img
                  src="images/icon-teamwork.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Teamwork
                  </h5>
                  <p className="mb-4 text-base">
                    We work together with our colleagues to achieve a common
                    goal and recognise each other’s strengths and contributions.
                  </p>
                </div>
              </div>

              <div className="text-center mb-10">
                <img
                  src="images/icon-service-excellence.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Service Excellence
                  </h5>
                  <p className="mb-4 text-base">
                    We provide the kind of service that meets and exceeds
                    customer expectations.
                  </p>
                </div>
              </div>

              <div className="text-center mb-10">
                <img
                  src="images/icon-safety.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Safety
                  </h5>
                  <p className="mb-4 text-base">
                    We ensure that we work safely, and our customers and
                    colleagues are safe.
                  </p>
                </div>
              </div>

              <div className="text-center mb-10">
                <img
                  src="images/icon-communication.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Communication
                  </h5>
                  <p className="mb-4 text-base">
                    We share information with our customers and colleagues in an
                    open and honest way.
                  </p>
                </div>
              </div>

              <div className="text-center mb-10">
                <img
                  src="images/icon-performance-driven.svg"
                  className="w-auto h-16 m-auto"
                  alt=""
                />

                <div className="w-full">
                  <h5 className="text-xl mt-2 mb-0 font-semibold text-[#ceb596]">
                    Performance Driven
                  </h5>
                  <p className="mb-4 text-base">
                    We are performance driven with the ability to venture into
                    new areas of opportunity whilst offering a quality service
                    to our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative ">
          <div className="absolute top-0 right-0 bg-[#46515e] lg:w-1/2 w-full h-full -z-10"></div>
          <div
            className="lg:w-1/2 w-full h-full bg-no-repeat lg:bg-center bg-top bg-cover lg:absolute lg:min-h-0 min-h-80 left-0 top-0"
            style={{
              backgroundImage: `url(${
                prasaLeadershipMedia?.find(
                  (media) => media.id === prasaLeadership?.featured_media
                )?.source_url
              })`,
            }}
          ></div>
          <div className="md:flex container mx-auto justify-end md:pb-0 pb-16">
            <div className="lg:w-1/2 w-full text-white 2xl:pb-60 md:py-16 py-10 lg:pl-16 px-8">
              <NavLink
                to={WEB_ROUTES.TEAM}
                className="font-medium text-4xl text-[#06a7e0]"
              >
                {prasaLeadership?.title?.rendered}
              </NavLink>
              <div className="mt-4">
                <p
                  dangerouslySetInnerHTML={{
                    __html: prasaLeadership?.content?.rendered,
                  }}
                ></p>
              </div>
              <NavLink
                to={WEB_ROUTES.TEAM}
                className="flex text-[#06a7e0] font-medium text-xl mt-10 float-start"
              >
                <span>See our leadership and board</span>
                <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
              </NavLink>
            </div>
          </div>
        </section>

        <section className="py-10 xl:px-0 px-8 bg-white">
          <DivisionsScroller title="Organisational Structure and Operating Model" />
        </section>
      </main>
      <Footer />
    </>
  );
}
