export default function SocialIcons({ colour }) {
  return (
    <>
      <a
        href="https://www.linkedin.com/company/prasa-group/"
        target="_blank"
        rel="noreferrer"
        className="bg-white lg:w-7 lg:h-7 md:w-6 md:h-6 w-7 h-7 flex items-center justify-center rounded-full"
        title="LinkedIn"
      >
        <svg
          className="lg:h-4 lg:w-4 h-3 w-3"
          fill={colour ? colour : "#06a7e0"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
        </svg>
      </a>
      <a
        href="https://x.com/prasa_group/"
        target="_blank"
        rel="noreferrer"
        className="bg-white lg:w-7 lg:h-7 md:w-6 md:h-6 w-7 h-7 flex items-center justify-center rounded-full"
        title="Twitter"
      >
        <svg
          className="h-4 w-4 my-1 bg-white"
          fill={colour ? colour : "#06a7e0"}
          enableBackground="new 0 0 1226.37 1226.37"
          viewBox="0 0 1226.37 1226.37"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"></path>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </a>

      <a
        href="https://www.facebook.com/prasagroupcorporate/"
        target="_blank"
        rel="noreferrer"
        className="bg-white lg:w-7 lg:h-7 md:w-6 md:h-6 w-7 h-7 flex items-center justify-center rounded-full"
        title="Facebook"
      >
        <svg
          className="h-5 w-5"
          fill={colour ? colour : "#06a7e0"}
          id="fi_3128208"
          enable-background="new 0 0 100 100"
          height="512"
          viewBox="0 0 100 100"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="_x30_1._Facebook">
            <path
              id="Icon_11_"
              d="m40.4 55.2c-.3 0-6.9 0-9.9 0-1.6 0-2.1-.6-2.1-2.1 0-4 0-8.1 0-12.1 0-1.6.6-2.1 2.1-2.1h9.9c0-.3 0-6.1 0-8.8 0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2v12.1c0 1.6-.5 2.1-2.2 2.1-3.6 0-11.3 0-11.6 0v32.6c0 1.7-.5 2.3-2.3 2.3-4.2 0-8.3 0-12.5 0-1.5 0-2.1-.6-2.1-2.1 0-10.5 0-32.4 0-32.7z"
            ></path>
          </g>
        </svg>
      </a>

      <a
        href="https://www.instagram.com/prasa_group/"
        target="_blank"
        rel="noreferrer"
        className="bg-white lg:w-7 lg:h-7 md:w-6 md:h-6 w-7 h-7 flex items-center justify-center rounded-full"
        title="Instagram"
      >
        <svg
          className="h-5 w-5"
          fill={colour ? colour : "#06a7e0"}
          id="fi_3670274"
          height="512"
          viewBox="0 0 512 512"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
        >
          <circle cx="256" cy="256" r="52.5"></circle>
          <path d="m256 6c-138.071 0-250 111.929-250 250s111.929 250 250 250 250-111.929 250-250-111.929-250-250-250zm154.458 313.54c-1.2 23.768-7.879 47.206-25.2 64.343-17.489 17.3-41.038 23.746-65.035 24.934h-128.445c-24-1.188-47.546-7.63-65.035-24.934-17.322-17.137-24-40.575-25.2-64.343v-127.08c1.2-23.768 7.879-47.206 25.2-64.344 17.489-17.3 41.038-23.746 65.035-24.933h128.444c24 1.187 47.546 7.63 65.035 24.933 17.322 17.138 24 40.576 25.2 64.344z"></path>
          <path d="m318.6 132.138c-31.286-.858-93.906-.858-125.192 0-16.281.447-34.738 4.5-46.338 16.89-12.054 12.879-16.609 28.439-17.071 45.846-.812 30.552 0 122.252 0 122.252.529 17.405 5.017 32.967 17.071 45.846 11.6 12.394 30.057 16.443 46.338 16.89 31.286.858 93.906.858 125.192 0 16.281-.447 34.738-4.5 46.338-16.89 12.054-12.879 16.609-28.439 17.071-45.846v-122.252c-.462-17.407-5.017-32.967-17.071-45.846-11.604-12.394-30.061-16.443-46.338-16.89zm-62.6 205.237a81.375 81.375 0 1 1 81.375-81.375 81.375 81.375 0 0 1 -81.375 81.375zm81.721-145.953a16.275 16.275 0 1 1 16.279-16.275 16.275 16.275 0 0 1 -16.279 16.275z"></path>
        </svg>
      </a>

      <a
        href="https://www.youtube.com/channel/UCyV7yBiy1dsI0Uh1i_yc1DA"
        target="_blank"
        rel="noreferrer"
        className="bg-white lg:w-7 lg:h-7 md:w-6 md:h-6 w-7 h-7 flex items-center justify-center rounded-full"
        title="Youtube"
      >
        <svg
          className="h-5 w-5"
          fill={colour ? colour : "#06a7e0"}
          viewBox="-21 -117 682.66672 682"
          width="682pt"
          xmlns="http://www.w3.org/2000/svg"
          id="fi_1384028"
        >
          <path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"></path>
        </svg>
      </a>
    </>
  );
}
