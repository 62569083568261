export default function PublicationsItem({ publication, publicationMedia, i }) {
  return (
    <>
      <div
        className={`${
          i % 2 === 0 ? "bg-[#e0e0e2]" : "bg-white"
        } w-full py-10 px-10`}
      >
        <div className="mb-4">
          <a
            href={publicationMedia?.source_url}
            className="text-[#06a7e0] text-xl font-semibold hover:underline hover:cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: publication?.title?.rendered,
            }}
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: publication?.content?.rendered,
          }}
        />
        <a
          href={publicationMedia?.source_url}
          className="flex text-[#25374f] hover:text-[#06a7e0] float-start"
          target="_blank"
          rel="noreferrer"
        >
          <i className="mr-1 mt-0.5 fi fi-sr-file-download text-[#06a7e0]"></i>
          <span>View publication</span>
          <i className="ml-2 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
        </a>
        <div className="clear-both"></div>
      </div>
    </>
  );
}
