import { useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import JobItem from "../components/job-item";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { useMatch } from "react-router-dom";

export default function Careers() {
  const location = useLocation();
  const careerType = useMatch(WEB_ROUTES.CAREERS + "/:type");

  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [jobs, setJobs] = useState([]);
  let { getMetaSnippet } = useUtilities();
  const [jobsMedia, setJobsMedia] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/pages",
          careerType?.params?.type ? careerType?.params?.type : "careers"
        )
      )
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [careerType]);

  useEffect(() => {
    if (
      careerType?.params?.type === undefined ||
      careerType?.params?.type === null
    )
      getJobs();

    setFilter("");
    setFilterBy("");
  }, [careerType]);

  const getJobs = (type) => {
    //Get jobs
    axios
      .get(CONTENT_ROUTES.JOBS("?order_by=menu_order&order=desc&per_page=100"))
      .then((res) => {
        setJobs(res.data);
        setAllJobs(res.data); // Store the original list

        //Get jobs media
        let media = [];

        res.data?.map((job, i) => {
          if (job?.acf?.full_job_descriptions)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(job?.acf?.full_job_descriptions))
              .then((res) => {
                media[i] = res.data;
                setJobsMedia((prevState) => [...prevState, media[i]]);
              });
          return job?.acf?.full_job_descriptions;
        });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    //Default by title
    let filterByResult = allJobs.filter((job) =>
      job?.title?.rendered?.toLowerCase()?.includes(filter.toLowerCase())
    );

    if (filter && filterBy) {
      //Default by salary_group
      if (filterBy?.toLowerCase() === "salary group")
        filterByResult = allJobs.filter((job) =>
          job?.acf?.salary_group.toLowerCase()?.includes(filter.toLowerCase())
        );
      //Default by location
      if (filterBy?.toLowerCase() === "location")
        filterByResult = allJobs.filter((job) =>
          job?.acf?.location.toLowerCase()?.includes(filter.toLowerCase())
        );
      //Default by reference
      if (filterBy?.toLowerCase() === "reference")
        filterByResult = allJobs.filter((job) =>
          job?.acf?.reference.toLowerCase()?.includes(filter.toLowerCase())
        );
      setJobs(filterByResult);
    }

    //Search by all
    if (filter && !filterBy)
      setJobs(
        allJobs.filter(
          (job) =>
            job?.title?.rendered
              ?.toLowerCase()
              ?.includes(filter.toLowerCase()) ||
            job?.acf?.salary_group
              .toLowerCase()
              ?.includes(filter.toLowerCase()) ||
            job?.acf?.location.toLowerCase()?.includes(filter.toLowerCase()) ||
            job?.acf?.reference.toLowerCase()?.includes(filter.toLowerCase())
        )
      );

    if (!filter) {
      setJobs(allJobs); // Reset to original list if input is cleared
    }
  }, [filter, filterBy, allJobs]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <h1
              className="pb-5 text-4xl font-semibold xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: page?.title?.rendered,
              }}
            />
            {careerType?.params?.type === undefined ||
            careerType?.params?.type === null ? (
              <div
                className="xl:px-0 px-8"
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered?.replace(
                    /<p.*?>/g,
                    "<p class='mb-5'>"
                  ),
                }}
              />
            ) : null}
            <div className="mt-10 container mx-auto flex bg-[#46515e]">
              <NavLink
                to={WEB_ROUTES.CAREERS}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  (careerType?.params?.type === undefined ||
                    careerType?.params?.type === null) &&
                  "bg-[#626873]"
                }`}
              >
                Job Opportunities
              </NavLink>
              <NavLink
                to={WEB_ROUTES.CAREERS + "/scholarship-bursaries"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("scholarship-bursaries") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Scholarship & Bursaries
              </NavLink>
              <NavLink
                to={WEB_ROUTES.CAREERS + "/academic-partnerships"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("academic-partnerships") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Academic Partnerships
              </NavLink>
            </div>
          </div>
        </section>

        <section className="relative">
          <div
            className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat xl:bg-[left_-200px_top_0px] bg-[center_top_0px] bg-[length:auto_100%]"
            style={{
              backgroundImage: `url(${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              })`,
            }}
          ></div>

          <div className="container mx-auto xl:px-0 px-8">
            <div className="md:flex md:justify-end">
              <div className="md:w-9/12 xl:pr-10 px-0 bg-white min-h-96">
                {careerType?.params?.type === undefined ||
                careerType?.params?.type === null ? (
                  <>
                    <div className="py-10">
                      <h2 className="text-3xl font-medium md:px-10 text-neutral-700 xl:float-start first-letter:uppercase capitalize">
                        Job Opportunities <>({jobs?.length})</>
                      </h2>

                      <>
                        <div className="flex xl:float-end xl:mt-0 mt-4 md:pl-10">
                          <div className="relative rounded-md shadow-sm">
                            <input
                              onChange={(event) => {
                                setFilter(event.target.value);
                              }}
                              type="search"
                              name="filter"
                              id="filter"
                              value={filter}
                              className="xl:float-end block md:w-[350px] w-full rounded-md border border-gray-200 py-2 pr-36 pl-3 placeholder:text-gray-400 text-base !outline-none"
                              placeholder={`Search jobs`}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center border-l">
                              <select
                                onChange={(event) => {
                                  setFilterBy(event.target.value);
                                }}
                                id="filterBy"
                                name="filterBy"
                                value={filterBy}
                                className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-2 text-gray-500 text-sm !outline-none"
                              >
                                <option value="">All</option>
                                <option value="Title">Job Title</option>
                                <option value="Salary Group">
                                  Salary Group
                                </option>
                                <option value="Location">Location</option>
                                <option value="Reference">Reference</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="clear-both"></div>
                        {filter ? (
                          <div className="p-2 md:ml-10 md:mt-2 mt-5 bg-[#ceb596] rounded text-white text-base">
                            <strong>Search results for:</strong> {filter}{" "}
                            {filter && filterBy
                              ? "(" + filterBy + ")"
                              : filterBy}
                          </div>
                        ) : null}
                      </>
                    </div>

                    <div className="container mx-auto pb-16">
                      {jobs?.length ? (
                        <>
                          {jobs?.map((job, i) => (
                            <div key={i}>
                              <JobItem job={job} jobsMedia={jobsMedia} i={i} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="md:py-4 md:px-10">
                          There are currently no job opportunities.
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <article className="md:p-10 py-10">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </article>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
