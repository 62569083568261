import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import SocialIcons from "./social-icons";

export default function Footer() {
  return (
    <footer className="bg-[#46515e]">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto xl:container md:py-16 py-10 xl:px-0 md:px-4 px-8">
        <div className="md:flex">
          <div className="text-white md:w-3/12">
            <NavLink to={WEB_ROUTES.HOME} className="inline-block">
              <img
                className="mb-5 h-20"
                src="/images/prasa-logo-blue.svg"
                alt="PRASA Logo"
              />
            </NavLink>
            <div className="max-w-md mb-4 xl:text-lg text-base">
              <p>
                We are committed to delivering high-quality passenger service on
                a sustainable basis.
              </p>
            </div>
          </div>

          <div className="md:grid md:grid-cols-4 md:divide-x md:divide-gray-500 md:w-9/12">
            <div className="mt-10 md:mt-0 col-span-1 xl:px-10 md:px-4 xl:text-lg text-base">
              <h3 className="font-medium text-[#06a7e0] mb-6 xl:text-2xl text-xl">
                Divisions
              </h3>
              <ul className="space-y-3">
                <li>
                  <NavLink
                    to={WEB_ROUTES.DIVISION_PRASA_RAIL}
                    className="text-white hover:text-neutral-400"
                  >
                    PRASA Rail
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.DIVISION_PRASA_CRES}
                    className="text-white hover:text-neutral-400"
                  >
                    PRASA Cres
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.DIVISION_PRASA_TECH}
                    className="text-white hover:text-neutral-400"
                  >
                    PRASA Tech
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.DIVISION_AUTOPAX}
                    className="text-white hover:text-neutral-400"
                  >
                    Autopax
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.DIVISION_INTERSITE}
                    className="text-white hover:text-neutral-400"
                  >
                    Intersite
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="mt-10 md:mt-0 col-span-1 xl:px-10 md:px-4 xl:text-lg text-base">
              <h3 className="font-medium text-[#06a7e0] mb-6 xl:text-2xl text-xl">
                Quick Links
              </h3>
              <ul className="space-y-3">
                <li>
                  <NavLink
                    to={WEB_ROUTES.HOME}
                    className="text-white hover:text-neutral-400"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.ABOUT}
                    className="text-white hover:text-neutral-400"
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.PROCUREMENT}
                    className="text-white hover:text-neutral-400"
                  >
                    Tenders
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.NEWS}
                    className="text-white hover:text-neutral-400"
                  >
                    News & Media
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.CAREERS}
                    className="text-white hover:text-neutral-400"
                  >
                    Careers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.CONTACT}
                    className="text-white hover:text-neutral-400"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="mt-10 md:mt-0 col-span-1 xl:px-10 md:px-4 xl:text-lg text-base">
              <h3 className="font-medium text-[#06a7e0] mb-6 xl:text-2xl text-xl">
                Legal
              </h3>
              <ul className="space-y-3 text-white">
                <li>
                  <NavLink
                    to={WEB_ROUTES.PRIVACY_POLICY}
                    className="text-white hover:text-neutral-400"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.TERMS}
                    className="text-white hover:text-neutral-400"
                  >
                    Terms of Use
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.COPYRIGHT_STATEMENT}
                    className="text-white hover:text-neutral-400"
                  >
                    Copyright Statement
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.COOKIE_POLICY}
                    className="text-white hover:text-neutral-400"
                  >
                    Cookie Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={WEB_ROUTES.POPI_PAIA}
                    className="text-white hover:text-neutral-400"
                  >
                    POPIA and PAIA Statement
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="mt-10 md:mt-0 xl:pl-10 md:pl-4 text-white xl:text-lg text-base">
              <h3 className="font-medium text-[#06a7e0] mb-6 xl:text-2xl text-xl">
                Contacts
              </h3>
              <a className="mb-3" href="tel:+27110131667">
                <svg
                  className="w-6 h-6 mr-2 inline-block float-left"
                  id="fi_5585846"
                  fill="#ffffff"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                >
                  <path
                    d="m256 0c141.385 0 256 114.615 256 256s-114.615 256-256 256-256-114.615-256-256 114.615-256 256-256zm150.3 374.436a19.622 19.622 0 0 0 0-27.678l-50.111-50.1a19.63 19.63 0 0 0 -27.69 0l-13.617 13.623a29.8 29.8 0 0 1 -35.245 5.279 200.184 200.184 0 0 1 -83.193-83.183 29.82 29.82 0 0 1 5.27-35.257l13.643-13.62a19.631 19.631 0 0 0 0-27.685l-50.111-50.095a19.629 19.629 0 0 0 -27.691 0c-2.071 2.065-4.691 4.56-7.493 7.2-7.007 6.623-15.749 14.866-19.283 20.048-18.613 27.239-9.687 63.681 1.036 89.459 14.165 33.977 40.271 71 73.536 104.242 33.235 33.238 70.246 59.347 104.242 73.512 25.772 10.738 62.2 19.642 89.438 1.033 5.179-3.537 13.434-12.258 20.044-19.274 2.651-2.797 5.148-5.44 7.225-7.504z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="float-left">+27 11 013 6700</span>
                <div className="clear-both"></div>
              </a>
              <a className="mb-3" href="mailto:info@prasa.com">
                <svg
                  className="w-6 h-6 mr-2 mt-2 inline-block float-left"
                  id="fi_3178158"
                  fill="#ffffff"
                  enable-background="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="m256 512c-141.159 0-256-114.841-256-256s114.841-256 256-256 256 114.841 256 256-114.841 256-256 256zm132.758-166.069c3.156 0 5.725-2.568 5.725-5.725v-160.717l-130.25 103.432c-2.41 1.915-5.323 2.872-8.234 2.872s-5.823-.958-8.234-2.872l-130.249-103.432v160.717c0 3.157 2.569 5.725 5.725 5.725zm-19.96-179.862-112.798 89.575-112.799-89.575zm52.168 5.725v168.414c0 17.759-14.449 32.208-32.208 32.208h-265.517c-17.76 0-32.207-14.449-32.207-32.208v-168.414c0-17.759 14.448-32.207 32.207-32.207h265.517c17.759-.001 32.208 14.448 32.208 32.207z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="float-left mt-2">info@prasa.com</span>
                <div className="clear-both"></div>
              </a>{" "}
              <div className="flex gap-x-1.5 md:gap-x-1 mt-8">
                <SocialIcons colour={`#46515e`} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-10 text-white xl:px-0 px-8">
        <div className="mx-auto container md:space-x-4 space-x-0">
          <div className="md:float-left float-left mb-0 md:mt-0 mt-4 xl:text-lg text-base">
            &copy; Copyright {new Date().getFullYear()}. PRASA. All rights
            reserved.
          </div>

          <div className="clear-both"></div>
        </div>
      </div>
    </footer>
  );
}
