import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import NewsItem from "../components/news-item";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { useMatch } from "react-router-dom";

export default function News() {
  const location = useLocation();
  const newsCategory = useMatch(WEB_ROUTES.NEWS + "/category/:category");
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    newsCategory?.params?.category
      ? axios
          .get(CONTENT_ROUTES.POST_CATEGORY(newsCategory?.params?.category))
          .then((res) => {
            if (res?.data?.length) {
              axios
                .get(
                  CONTENT_ROUTES.POST_PAGE(
                    "/posts",
                    "&categories=" +
                      res?.data[0]?.id +
                      "&_embed&order_by=menu_order&order=desc&per_page=100"
                  )
                )
                .then((res) => {
                  setPosts(res.data);
                  setAllPosts(res.data); // Store the original list
                })
                .catch((err) => {})
                .finally(() => {});
            }
          })
          .catch((err) => {})
          .finally(() => {})
      : axios
          .get(
            CONTENT_ROUTES.POST_PAGE(
              "/posts",
              "&_embed&order_by=menu_order&order=desc&per_page=100&categories_exclude=4"
            )
          )
          .then((res) => {
            setPosts(res.data);
            setAllPosts(res.data); // Store the original list
          })
          .catch((err) => {})
          .finally(() => {});
  }, [newsCategory]);

  return (
    <>
      <Helmet>
        <title>{`Latest News and Media - PRASA`}</title>
        <meta
          name="description"
          content={`Stay up-to-date with the latest news, trends, and expert insights in rail infrastructure. Explore expert analysis, updates, and in-depth articles on industry developments.`}
        />
      </Helmet>

      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <div className="text-base leading-7 xl:px-0 px-8">
              <h1 className="pb-5 text-4xl font-medium">News and Media</h1>
            </div>
            <div className="mt-10 container mx-auto flex bg-[#46515e]">
              <NavLink
                to={WEB_ROUTES.NEWS}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  (newsCategory?.params?.category === undefined ||
                    newsCategory?.params?.category === null) &&
                  "bg-[#626873]"
                }`}
              >
                Latest News
              </NavLink>
              <NavLink
                to={WEB_ROUTES.NEWS_CATEGORY + "/press-releases"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("press-releases") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Press Releases
              </NavLink>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container mx-auto xl:px-0 px-8 pb-16">
            <div>
              <h2 className="text-3xl md:py-10 pt-10 font-medium text-neutral-700 md:float-start first-letter:uppercase">
                {newsCategory?.params?.category ? (
                  <span className="capitalize">
                    {newsCategory?.params?.category?.replace("-", " ")}
                  </span>
                ) : (
                  "All News and Media"
                )}
              </h2>
              <div className="py-10">
                <label for="filter" className="sr-only">
                  Search{" "}
                  {newsCategory?.params?.category
                    ? newsCategory?.params?.category?.replace("-", " ")
                    : "news"}
                </label>
                <input
                  onChange={(event) => {
                    const searchTerm = event.target.value.toLowerCase();
                    if (searchTerm) {
                      setPosts(
                        allPosts.filter((post) =>
                          post?.title?.rendered
                            ?.toLowerCase()
                            ?.includes(searchTerm)
                        )
                      );
                    } else {
                      setPosts(allPosts); // Reset to original list if input is cleared
                    }
                  }}
                  type="search"
                  name="filter"
                  id="filter"
                  className="md:float-end block w-72 rounded-md border border-gray-300 py-2 px-3 placeholder:text-gray-400 text-base"
                  placeholder={`Search ${
                    newsCategory?.params?.category
                      ? newsCategory?.params?.category?.replace("-", " ")
                      : "news"
                  }`}
                />
              </div>
              <div className="clear-both"></div>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:grid-cols-3">
              {posts?.map((article, i) => (
                <Fragment key={i}>
                  <NewsItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
