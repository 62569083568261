import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function JobItem({ job, jobsMedia, i }) {
  return (
    <>
      <div
        className={`${
          i % 2 === 0 ? "bg-[#e0e0e2]" : "bg-white"
        } w-full py-10 px-10`}
      >
        <div className="mb-4">
          <NavLink
            to={WEB_ROUTES.JOBS + `/` + job?.slug}
            className="text-[#06a7e0] text-xl font-semibold hover:underline hover:cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: job?.title?.rendered,
            }}
          />
        </div>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: job?.excerpt?.rendered,
          }}
        />
        <p>
          <span className="font-semibold">Salary Group:</span>{" "}
          {job?.acf?.salary_group}
        </p>
        <p>
          <span className="font-semibold">Location:</span> {job?.acf?.location}
        </p>
        <p>
          <span className="font-semibold">Reference:</span>{" "}
          {job?.acf?.reference}
        </p>
        <p>
          <span className="font-semibold">Closing Date:</span>{" "}
          {job?.acf?.closing_date}
        </p>
        <NavLink
          to={WEB_ROUTES.JOBS + `/` + job?.slug}
          className="mt-5 flex hover:text-[#06a7e0] float-start"
        >
          <span>Read more</span>
          <i className="ml-2 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
        </NavLink>
        <div className="clear-both"></div>
      </div>
    </>
  );
}
