import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function NewsItem({ article }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <div className="flex flex-col items-start ">
        <NavLink
          to={WEB_ROUTES.NEWS + `/` + article?.slug}
          className="relative w-full md:pt-0 pt-60 bg-no-repeat bg-center bg-cover md:bg-none"
          style={{
            backgroundImage: `url(${article?._embedded["wp:featuredmedia"][0]?.source_url})`,
          }}
        >
          <div className="overflow-hidden md:inline-block hidden h-full">
            <img
              src={article?._embedded["wp:featuredmedia"][0]?.source_url}
              alt={article?.title?.rendered}
              className="aspect-[16/9] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
          </div>

          <div className="absolute bottom-0 right-0 bg-[#06a7e0]/60 py-2 px-4 ">
            <p
              className="text-white leading-5"
              dangerouslySetInnerHTML={{
                __html:
                  "<span class='font-medium text-3xl'>" +
                  new Date(article.date).getDate() +
                  "</span><br /> " +
                  monthNames[new Date(article.date).getMonth()] +
                  "<br /> " +
                  new Date(article.date).getFullYear(),
              }}
            />
          </div>
        </NavLink>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-6">
              <NavLink
                className="text-2xl text-[#06a7e0] hover:underline"
                to={WEB_ROUTES.NEWS + `/` + article?.slug}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: article?.title?.rendered,
                  }}
                />
              </NavLink>
            </h3>

            <p
              className="text-[#46515e]"
              dangerouslySetInnerHTML={{
                __html: article?.excerpt?.rendered,
              }}
            />

            <NavLink
              to={WEB_ROUTES.NEWS + `/` + article?.slug}
              className="mt-5 flex hover:text-[#06a7e0] float-start"
            >
              <span>Read more</span>
              <i className="text-xl ml-1 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
