import { useState, useEffect } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { useMatch } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function NewsSingle() {
  const [article, setArticle] = useState({});
  const singleArticle = useMatch(WEB_ROUTES.NEWS + "/:news");
  let { getMetaSnippet } = useUtilities();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          singleArticle?.params?.news + "&_embed&order_by=menu_order&order=asc"
        )
      )
      .then((res) => {
        let serviceResponse = res.data?.length ? res.data[0] : {};
        setArticle(serviceResponse);
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleArticle]);

  return (
    <>
      <Helmet>
        <title>{article?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(article?.excerpt?.rendered)}
        />
        <meta property="og:title" content={article?.title?.rendered} />
        <meta
          property="og:url"
          content={`https://www.prasa.com/news/` + article?.slug}
        />
        <meta
          property="og:image"
          content={
            Object.keys(article).length
              ? article?._embedded["wp:featuredmedia"][0]?.source_url
              : ""
          }
        />
        <meta
          property="og:description"
          content={getMetaSnippet(article?.excerpt?.rendered)}
        />
        <meta property="og:site_name" content="PRASA" />
      </Helmet>
      <Header />
      <main>
        {Object.keys(article).length ? (
          <>
            <section className="bg-[#06a7e0] text-white">
              <div className="container mx-auto">
                <div className="mx-auto md:flex">
                  <div className="w-full py-16">
                    <div className="relative xl:px-0 px-8">
                      <span
                        className="font-semibold text-[#46515e]"
                        dangerouslySetInnerHTML={{
                          __html:
                            new Date(article.date).getDate() +
                            " " +
                            monthNames[new Date(article.date).getMonth()] +
                            " " +
                            new Date(article.date).getFullYear(),
                        }}
                      />
                      <h1 className="pt-2 text-4xl font-semibold">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: article?.title?.rendered,
                          }}
                        />
                      </h1>
                      <p className="mt-5">Share this post:</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="relative">
              <div className="absolute top-0 left-0 bg-[#06a7e0] w-full h-40 -z-10"></div>
              <div className="container mx-auto xl:px-0 px-8">
                <div className="mx-auto md:flex">
                  <article className="md:w-9/12 w-full pb-16 bg-white">
                    <img
                      src={
                        article?._embedded["wp:featuredmedia"][0]?.source_url
                      }
                      className={`mb-5 m-auto object-cover`}
                      alt=""
                    />
                    <div
                      className="md:pt-0 pt-4"
                      dangerouslySetInnerHTML={{
                        __html: article?.content?.rendered?.replace(
                          /<p>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </article>
                </div>
              </div>
            </section>
          </>
        ) : null}
      </main>
      <Footer />
    </>
  );
}
