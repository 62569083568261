import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { NavLink, useMatch } from "react-router-dom";
import DivisionsScroller from "../components/divisions-scroller";

export default function Divisions() {
  const divisionSlug = useMatch(WEB_ROUTES.DIVISIONS + "/:division");

  const [division, setDivision] = useState({});
  const [divisionMedia, setDivisionMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    //Get division
    axios
      .get(CONTENT_ROUTES.DIVISIONS("?slug=" + divisionSlug?.params?.division))
      .then((res) => {
        let projectResponse = res.data?.length ? res.data[0] : {};

        setDivision(projectResponse);

        //Get media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(projectResponse?.id))
          .then((res) => {
            setDivisionMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [divisionSlug]);

  return (
    <>
      <Helmet>
        <title>{division?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(division?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="relative">
          <div className="container mx-auto">
            <div className="md:flex md:justify-end">
              <div className="md:w-3/12 lg:p-16 px-8 py-16 bg-white">
                <p className="text-[#46515e] mb-4 font-semibold">
                  {division?.acf?.type}
                </p>
                <img
                  src={
                    divisionMedia?.find(
                      (media) => media?.id === division?.featured_media
                    )?.source_url
                  }
                  className={`mt-5 w-full h-auto`}
                  alt=""
                />
              </div>
              <div className="md:w-9/12 md:p-16 px-8 py-16 bg-[#06a7e0] text-white">
                <h1
                  className="mb-10 text-4xl font-semibold float-start"
                  dangerouslySetInnerHTML={{
                    __html: division?.title?.rendered,
                  }}
                />
                {divisionSlug?.params?.division === "prasa-cres" ? (
                  <NavLink
                    to={WEB_ROUTES.REAL_ESTATE}
                    className="flex text-white bg-[#ceb596] px-5 py-2 md:float-end float-start mb-4 rounded-full"
                  >
                    View Vacant Premises
                  </NavLink>
                ) : null}

                {divisionSlug?.params?.division === "prasa-rail" ? (
                  <NavLink
                    to={WEB_ROUTES.RAIL_OPERATIONS}
                    className="flex text-white bg-[#ceb596] px-5 py-2 md:float-end float-start mb-4 rounded-full"
                  >
                    View Rail Operations
                  </NavLink>
                ) : null}

                {divisionSlug?.params?.division ===
                "city-to-city-and-translux" ? (
                  <a
                    href="https://res.prasa.com/citytocity/booking"
                    target="_blank"
                    rel="noreferrer"
                    className="flex text-white bg-[#ceb596] px-5 py-2 md:float-end float-start mb-4 rounded-full"
                  >
                    Book City-to-City
                  </a>
                ) : null}
                <div className="clear-both"></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: division?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-10 xl:px-0 px-8 bg-white">
          <DivisionsScroller title={"PRASA divisions and subsidiaries"} />
        </section>
      </main>
      <Footer />
    </>
  );
}
