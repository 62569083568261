import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import PublicationsItem from "../components/publications-item";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { useMatch } from "react-router-dom";

export default function Publications() {
  const location = useLocation();
  const publicationType = useMatch(WEB_ROUTES.PUBLICATIONS + "/:type");

  const [page, setPage] = useState({});

  const [pageMedia, setPageMedia] = useState([]);
  const [allPublications, setAllPublications] = useState([]);
  const [publications, setPublications] = useState([]);
  let { getMetaSnippet } = useUtilities();
  const [publicationsMedia, setPublicationsMedia] = useState([]);
  const [publicationsDocument, setPublicationsDocument] = useState([]);

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "publications"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (publicationType?.params?.type === "corporate-plans")
      getPublications("corporate-plan");
    else if (publicationType?.params?.type === "fact-sheets")
      getPublications("fact-sheet");
    else getPublications("annual-report");
  }, [publicationType]);

  const getPublications = (type) => {
    axios
      .get(
        CONTENT_ROUTES.PUBLICATION(
          type,
          "?order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setPublications(res.data);
        setAllPublications(res.data); // Store the original list

        //Get publications media
        let media = [];

        res.data?.map((publication, i) => {
          if (publication?.acf?.document_link)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(publication?.acf?.document_link))
              .then((res) => {
                media[i] = res.data;
                setPublicationsDocument((prevState) => [
                  ...prevState,
                  media[i],
                ]);
              });
          return publication?.acf?.document_link;
        });

        //Get divisions media

        res.data?.map((publication, i) => {
          if (publication?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(publication?.featured_media))
              .then((res) => {
                setPublicationsMedia((prevState) => [...prevState, res.data]);
              });
          return publication?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <h1 className="pb-5 text-4xl font-semibold xl:px-0 px-8">
              {page?.title?.rendered}
            </h1>
            <div
              className="xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered?.replace(
                  /<p.*?>/g,
                  "<p class='mb-5'>"
                ),
              }}
            />
            <div className="mt-10 container mx-auto flex  bg-[#46515e]">
              <NavLink
                to={WEB_ROUTES.PUBLICATIONS + "/annual-reports"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  publicationType?.params?.type === undefined ||
                  publicationType?.params?.type === null ||
                  location.pathname.indexOf("annual-reports") !== -1
                    ? "bg-[#626873]"
                    : ""
                }`}
              >
                Annual Reports
              </NavLink>
              <NavLink
                to={WEB_ROUTES.PUBLICATIONS + "/corporate-plans"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("corporate-plans") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Corporate Plans
              </NavLink>
              <NavLink
                to={WEB_ROUTES.PUBLICATIONS + "/fact-sheets"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("fact-sheets") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Fact Sheets
              </NavLink>
            </div>
          </div>
        </section>

        <section className="relative">
          <div
            className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat xl:bg-[left_-200px_top_0px] bg-[center_top_0px] bg-[length:auto_100%]"
            style={{
              backgroundImage: `url(${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              })`,
            }}
          ></div>

          <div className="container mx-auto xl:px-0 px-8">
            <div className="md:flex justify-end">
              <div className="md:w-9/12 xl:pr-10 px-0 bg-white">
                <div className="py-10">
                  <h2 className="text-3xl font-medium md:px-10 text-neutral-700 xl:float-start first-letter:uppercase capitalize">
                    {publicationType?.params?.type
                      ? publicationType?.params?.type?.replace("-", " ")
                      : "annual reports"}
                  </h2>
                  <div className="flex xl:float-end xl:mt-0 mt-4 md:pl-10">
                    <div className="relative rounded-md shadow-sm">
                      <input
                        onChange={(event) => {
                          const searchTerm = event.target.value.toLowerCase();
                          if (searchTerm) {
                            setPublications(
                              allPublications.filter((post) =>
                                post?.title?.rendered
                                  ?.toLowerCase()
                                  ?.includes(searchTerm)
                              )
                            );
                          } else {
                            setPublications(allPublications); // Reset to original list if input is cleared
                          }
                        }}
                        type="search"
                        name="filter"
                        id="filter"
                        className="lg:float-end block w-72 rounded-md border border-gray-300 py-2 px-3 placeholder:text-gray-400 text-base"
                        placeholder={`Search ${
                          publicationType?.params?.type
                            ? publicationType?.params?.type?.replace("-", " ")
                            : "annual reports"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="clear-both"></div>
                </div>

                {location.pathname.indexOf("fact-sheets") !== -1 ? (
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-8 pb-16 md:pl-10">
                    {publications?.map((publication, i) => (
                      <a
                        className="relative"
                        key={i}
                        href={
                          publicationsDocument?.find(
                            (media) =>
                              media?.id === publication?.acf?.document_link
                          )?.source_url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            publicationsMedia?.find(
                              (media) =>
                                media?.id === publication?.featured_media
                            )?.source_url
                          }
                          className="w-full h-auto"
                          alt=""
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-[#06a7e0]/60 py-2 px-4 w-full text-white text-center"
                          dangerouslySetInnerHTML={{
                            __html: publication?.title?.rendered,
                          }}
                        ></div>
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="container mx-auto pb-16">
                    {publications?.map((publication, i) => (
                      <div key={i}>
                        <PublicationsItem
                          publication={publication}
                          publicationMedia={publicationsDocument?.find(
                            (media) =>
                              media?.id === publication?.acf?.document_link
                          )}
                          i={i}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
