import React, {
  Fragment,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import NewsItem from "../components/news-item";
import DivisionsScroller from "../components/divisions-scroller";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
  const [page, setPage] = useState({});
  const [aboutSecondaryMandate, setAboutSecondaryMandate] = useState({});

  const [whistleBlowingPage, setWhistleBlowingPage] = useState({});
  const [whistleBlowingPageMedia, setWhistleBlowingPageMedia] = useState([]);

  const [keyDivisionPage, setKeyDivisionPage] = useState([]);
  const [trainSchedules, setTrainSchedules] = useState([]);
  const [trainSchedulesMedia, setTrainSchedulesMedia] = useState([]);
  const [prasaCres, setPrasaCres] = useState([]);
  const [prasaCresMedia, setPrasaCresMedia] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerMedia, setBannerMedia] = useState([]);
  const [project, setProject] = useState([]);
  const [projectMedia, setProjectMedia] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [divisionsLogos, setDivisionsLogos] = useState([]);
  const [divisionsMedia, setDivisionsMedia] = useState([]);
  const [posts, setPosts] = useState([]);

  let { getMetaSnippet } = useUtilities();
  const [keyDivision, setKeyDivision] = useState(0);
  const [selectedMandate, setSelectedMandate] = useState("primary");
  const [announcement, setAnnouncement] = useState([]);

  const videoRef = useRef(null);
  const mainSliderRef = useRef(null);

  /* const handleSwiper = (swiperInstance) => {
    mainSliderRef.current = swiperInstance;

    // Check the first slide for a video and set delay if needed
    const firstSlide = swiperInstance.slides[0];
    const video = firstSlide?.querySelector("video");
    if (video) {
      video.onloadedmetadata = () => {
        setAutoplayDelay(video.duration * 1000);
      };
    }
  };

  const handleSlideChange = (swiper) => {
    if (!swiper.slides || !swiper.slides[swiper.realIndex]) return;

    const currentSlide = swiper.slides[swiper.realIndex];
    const video = currentSlide.querySelector("video");

    if (video && video.readyState >= 1) {
      setAutoplayDelay(video.duration * 1000); // Use video duration
    } else {
      setAutoplayDelay(5000); // Default delay for non-video slides
    }
  }; */

  /* VIDEO BANNER LOADING */
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playVideo = () => {
        if (window.innerWidth >= 768) {
          video.play().catch((error) => {
            console.error("Error attempting to play video:", error);
          });
        } else {
          video.pause();
          video.removeAttribute("autoplay");
        }
      };

      // Listen for when the video is ready
      const handleCanPlay = () => {
        playVideo();
      };

      video.addEventListener("canplay", handleCanPlay);

      // Handle screen resizing
      const handleResize = () => {
        playVideo();
      };

      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
        video.removeEventListener("canplay", handleCanPlay);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  /* ANNOUNCEMENTS */
  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.ANNOUNCEMENTS(
          "?order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        if (res?.data?.length) {
          setAnnouncement(res.data[0]);
        }
      });
  }, []);

  /* HOME */
  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "home"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get child pages
        axios
          .get(
            CONTENT_ROUTES.POST_PAGE_BY_PARENT(
              "/pages",
              pageResponse?.id + "&_embed"
            )
          )
          .then((res) => {
            let division = res.data.filter(
              (child) => child.slug === "prasa-key-divisions"
            );
            if (division?.length) setKeyDivisionPage(division[0]);

            let schedule = res.data.filter(
              (child) => child.slug === "home-train-schedules"
            );
            if (schedule?.length) {
              setTrainSchedules(schedule[0]);

              if (schedule[0]?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(schedule[0]?.featured_media))
                  .then((res) => {
                    setTrainSchedulesMedia(res.data);
                  });
            }

            let cres = res.data.filter(
              (child) => child.slug === "home-prasa-cres"
            );
            if (cres?.length) {
              setPrasaCres(cres[0]);
              axios
                .get(CONTENT_ROUTES.MEDIA_BY_PAGE(cres[0]?.id))
                .then((res) => {
                  setPrasaCresMedia(res.data);
                });
            }
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* DIVISIONS */
  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.DIVISIONS("?order_by=menu_order&order=asc&per_page=100")
      )
      .then((res) => {
        res.data = res.data.filter(
          (division) => division.acf.is_key_division === "Yes"
        );

        setDivisions(res.data);

        //Get divisions media

        res.data?.map((division, i) => {
          if (division?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(division?.featured_media))
              .then((res) => {
                setDivisionsLogos((prevState) => [...prevState, res.data]);
              });
          return division?.featured_media;
        });

        res.data?.map((division, i) => {
          if (division?.acf?.image_for_home_page)
            axios
              .get(
                CONTENT_ROUTES.MEDIA_BY_ID(division?.acf?.image_for_home_page)
              )
              .then((res) => {
                setDivisionsMedia((prevState) => [...prevState, res.data]);
              });
          return division?.acf?.image_for_home_page;
        });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* ABOUT US */
  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "about"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        //setAboutPage(pageResponse);

        //Get child pages
        axios
          .get(
            CONTENT_ROUTES.POST_PAGE_BY_PARENT(
              "/pages",
              pageResponse?.id + "&_embed"
            )
          )
          .then((res) => {
            let secondaryMandate = res.data.filter(
              (child) => child.slug === "prasas-secondary-mandate"
            );
            if (secondaryMandate?.length)
              setAboutSecondaryMandate(secondaryMandate[0]);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* NEWS POSTS */
  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          "&_embed&order_by=menu_order&order=desc&per_page=100&categories_exclude=4"
        )
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* BLOW A WHISTLE */
  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "blow-a-whistle"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setWhistleBlowingPage(pageResponse);

        //Get whistle blowing media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setWhistleBlowingPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* PROJECTS */
  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.PROJECTS("?order_by=menu_order&order=asc&per_page=100")
      )
      .then((res) => {
        if (res?.data?.length) {
          const randomNumber = Math.floor(Math.random() * res?.data?.length);
          let project = res.data[randomNumber];

          //Get projects media
          if (project?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(project?.featured_media))
              .then((res) => {
                setProject(project);

                setProjectMedia(res.data);
              });
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  /* BANNERS */
  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.BANNERS("?order_by=menu_order&order=asc"))
      .then((res) => {
        setBanners(res.data);

        //Get banner media
        let media = [];

        res.data?.map((banner, i) => {
          if (banner?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(banner?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setBannerMedia((prevState) => [...prevState, media[i]]);
              });
          return banner?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  const handleMainSliderPrev = useCallback(() => {
    if (!mainSliderRef.current) return;
    mainSliderRef.current.swiper.slidePrev();
  }, []);

  const handleMainSliderNext = useCallback(() => {
    if (!mainSliderRef.current) return;
    mainSliderRef.current.swiper.slideNext();
  }, []);

  const divisionSliderRef = useRef(null);

  const handleDivisionSliderPrev = useCallback(() => {
    if (!divisionSliderRef.current) return;
    divisionSliderRef.current.swiper.slidePrev();
  }, []);

  const handleDivisionSliderNext = useCallback(() => {
    if (!divisionSliderRef.current) return;
    divisionSliderRef.current.swiper.slideNext();
  }, []);

  const goToSlide = useCallback((index) => {
    setKeyDivision(index);
    if (!divisionSliderRef.current) return;
    divisionSliderRef.current.swiper.slideTo(index);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`PRASA - Passenger Rail Agency of South Africa`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section>
          <div className="relative banner">
            <Swiper
              className="2xl:h-[800px] md:h-[650px] h-[800px]"
              ref={mainSliderRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              direction={"horizontal"}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              /* onSwiper={handleSwiper} 
              onSlideChange={handleSlideChange}*/
            >
              {banners?.map((banner, i) =>
                i === 0 ? (
                  <SwiperSlide key={i} className="swiper-slide">
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      autoPlay
                      className="md:absolute inset-0 -z-10 h-full w-auto xl:w-full xl:h-auto object-cover"
                    >
                      <source
                        src="/videos/prasa-timelapse-loop-video.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support HTML video.
                    </video>

                    {/* <div className="absolute left-0 top-0 z-10 w-full h-full bg-gradient-to-b from-black md:to-50% to-100% opacity-20"></div> */}

                    <div className="md:container md:mx-auto z-20 md:relative absolute md:bottom-0 top-0 left-0">
                      <div className="relative min-h-40 banner-text xl:w-[500px] sm:w-[400px] w-full bg-[#06a7e0] md:px-10 px-8 pb-0 pt-10">
                        <h2
                          className="text-white xl:text-4xl text-2xl font-semibold"
                          dangerouslySetInnerHTML={{
                            __html: banner?.title?.rendered,
                          }}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: banner?.content?.rendered,
                          }}
                        />
                        {banner?.acf?.banner_cta?.title ? (
                          <NavLink
                            to={banner?.acf?.banner_cta?.url}
                            className="mt-5 flex text-white float-start"
                          >
                            <span>{banner?.acf?.banner_cta?.title}</span>{" "}
                            <i className="fi fi-rr-arrow-right text-xl float-right ml-1.5 mt-1"></i>
                          </NavLink>
                        ) : null}
                        <div className="clear-both"></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={i} className="swiper-slide">
                    <div
                      className="image !top-40 sm:!top-0"
                      style={{
                        backgroundImage: `url(${
                          bannerMedia?.find(
                            (media) => media.id === banner?.featured_media
                          )?.source_url
                        })`,
                      }}
                    ></div>

                    {/* <div className="absolute left-0 top-0 z-10 w-full h-full bg-gradient-to-b from-black md:to-50% to-100% opacity-20"></div> */}

                    <div className="md:container md:mx-auto z-20 relative">
                      <div className="relative min-h-40 banner-text xl:w-[500px] sm:w-[400px] w-full bg-[#06a7e0] md:px-10 px-8 pb-0 pt-10">
                        <h2
                          className="text-white xl:text-4xl text-2xl font-semibold"
                          dangerouslySetInnerHTML={{
                            __html: banner?.title?.rendered,
                          }}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: banner?.content?.rendered,
                          }}
                        />
                        {banner?.acf?.banner_cta?.title ? (
                          <NavLink
                            to={banner?.acf?.banner_cta?.url}
                            className="mt-5 flex text-white float-start"
                          >
                            <span>{banner?.acf?.banner_cta?.title}</span>{" "}
                            <i className="fi fi-rr-arrow-right text-xl float-right ml-1.5 mt-1"></i>
                          </NavLink>
                        ) : null}
                        <div className="clear-both"></div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>

            <div
              onClick={handleMainSliderPrev}
              className="swiper-button-prev px-6 py-10 bg-[#46515e] hover:bg-[#06a7e0]"
            >
              <i className="fi fi-br-angle-left text-4xl text-white inline-block mt-2"></i>
            </div>
            <div
              onClick={handleMainSliderNext}
              className="swiper-button-next px-6 py-10 bg-[#46515e] hover:bg-[#06a7e0]"
            >
              <i className="fi fi-br-angle-right text-4xl text-white inline-block mt-2"></i>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute top-0 left-0 bg-[#ceb596] w-1/2 h-full -z-10 md:block hidden"></div>
          <div className="md:flex container mx-auto items-stretch ">
            <div className="bg-[#ceb596] flex items-center text-2xl text-white md:p-5 md:pl-0 px-8 py-5 relative">
              <svg
                className="w-10 h-10"
                fill="#ffffff"
                id="fi_2326101"
                enable-background="new 0 0 512 512"
                height="512"
                viewBox="0 0 512 512"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="m437.018 39.687c-5.859-5.859-15.355-5.857-21.214 0-5.857 5.858-5.857 15.355.001 21.213 42.687 42.685 66.195 99.439 66.195 159.807 0 8.284 6.717 15 15 15 8.285 0 15-6.716 15-15 0-68.382-26.629-132.669-74.982-181.02z"></path>
                  <path d="m96.193 39.688c-5.859-5.859-15.355-5.857-21.214 0-48.35 48.354-74.979 112.64-74.979 181.019 0 8.284 6.716 15 15 15s15-6.716 15-15c0-60.365 23.508-117.119 66.193-159.806 5.858-5.858 5.858-15.355 0-21.213z"></path>
                  <path d="m370.357 85.126c-5.857 5.858-5.857 15.355.001 21.214 30.55 30.547 47.374 71.163 47.374 114.367 0 8.284 6.717 15 15 15 8.285 0 15-6.716 15-15 0-51.218-19.945-99.368-56.161-135.582-5.858-5.857-15.356-5.856-21.214.001z"></path>
                  <path d="m141.634 85.133c-5.858-5.857-15.355-5.856-21.213.001-36.212 36.216-56.155 84.363-56.155 135.573 0 8.284 6.717 15 15 15 8.285 0 15-6.716 15-15 0-43.197 16.823-83.812 47.369-114.36 5.857-5.859 5.857-15.357-.001-21.214z"></path>
                  <path d="m383.467 329.633v-76.793c0-28.01-8.918-54.589-25.789-76.864-14.102-18.616-33.333-33.16-54.939-41.718-3.009-23.112-22.821-41.018-46.739-41.018s-43.732 17.906-46.739 41.018c-21.606 8.558-40.837 23.102-54.938 41.718-16.872 22.275-25.79 48.854-25.79 76.864v76.793l-30.549 61.099c-2.324 4.649-2.076 10.172.656 14.594 2.733 4.423 7.562 7.114 12.76 7.114h289.2c5.199 0 10.027-2.691 12.76-7.114 2.733-4.422 2.982-9.944.657-14.594z"></path>
                  <path d="m256 476.707c27.005 0 50.886-13.584 65.204-34.268h-130.409c14.319 20.684 38.2 34.268 65.205 34.268z"></path>
                </g>
              </svg>{" "}
              <span className="ml-5">Announcements</span>
            </div>

            <div
              className="bg-white flex items-center py-5 md:px-10 px-8 !bg-none bg-no-repeat md:bg-[left_-30px_top_0px] md:bg-[length:auto_100%]"
              style={{
                backgroundImage: `url(/images/announcement-shape.svg)`,
              }}
            >
              <div
                className="line-clamp-2"
                dangerouslySetInnerHTML={{
                  __html: announcement?.content?.rendered,
                }}
              />
            </div>
            <div className="flex items-center justify-end md:py-5 md:px-0 pt-0 pb-5 px-8 bg-white">
              <NavLink
                to={WEB_ROUTES.ANNOUNCEMENTS}
                className="flex text-[#06a7e0] font-medium text-xl float-end"
              >
                <span>All&nbsp;announcements</span>
                <i className="text-xl mt-1 ml-2 fi fi-br-angle-small-right"></i>
              </NavLink>
            </div>
          </div>
        </section>

        <section
          className="relative bg-[#06a7e0] md:pb-20 pt-16 w-full bg-no-repeat 2xl:bg-[right_200px_top_100px] xl:bg-[right_-100px_top_100px] xl:bg-[length:auto_65%] lg:bg-[right_-100px_top_100px] lg:bg-[length:auto_65%] bg-[right_-200px_top_600px] bg-[length:auto_25%]"
          style={{
            backgroundImage: `url(/images/prasa-icon-blue.svg)`,
          }}
        >
          <div className="container mx-auto md:flex">
            <div className="mx-auto xl:w-1/2 w-full flex-shrink-0 xl:px-0 px-8 text-white">
              <nav
                className="flex mb-4 w-full md:space-x-2 space-x-1 text-center items-stretch"
                aria-label="Tabs"
              >
                <div
                  onClick={() => {
                    setSelectedMandate("primary");
                  }}
                  className={`py-3 hover:cursor-pointer px-4 rounded-full hover:bg-[#166f97] md:text-base text-sm ${
                    selectedMandate === "primary"
                      ? `bg-[#166f97]`
                      : `bg-[#72bbe8]`
                  }`}
                >
                  PRASA's Primary Mandate
                </div>
                <div
                  onClick={() => {
                    setSelectedMandate("secondary");
                  }}
                  className={`py-3 hover:cursor-pointer px-4 rounded-full hover:bg-[#166f97] md:text-base text-sm ${
                    selectedMandate === "secondary"
                      ? `bg-[#166f97]`
                      : `bg-[#72bbe8]`
                  }`}
                >
                  PRASA's Secondary Mandate
                </div>
              </nav>

              {selectedMandate === "primary" ? (
                <div>
                  <h1
                    className="pb-4 text-3xl font-medium"
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered
                        ?.replace(/<p.*?>*?<\/p>/g, "")
                        ?.replace(/<h2.*?>/g, ""),
                    }}
                  />

                  <div
                    className="md:mb-8 mb-4"
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered
                        ?.replace(/<h2.*?>*?<\/h2>/g, "")
                        ?.replace(/<p>/g, "<p class='mb-4'>"),
                    }}
                  />

                  <NavLink
                    to={WEB_ROUTES.ABOUT}
                    className="flex text-[#25374f] text-xl md:float-start"
                  >
                    <span>Learn more</span>
                    <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
                  </NavLink>
                </div>
              ) : null}

              {selectedMandate === "secondary" ? (
                <div>
                  <h2
                    className="pb-4 text-3xl font-medium"
                    dangerouslySetInnerHTML={{
                      __html: aboutSecondaryMandate?.title?.rendered,
                    }}
                  />
                  <div
                    className="md:mb-8 mb-4"
                    dangerouslySetInnerHTML={{
                      __html: aboutSecondaryMandate?.content?.rendered?.replace(
                        /<p>/g,
                        "<p className='mb-4'>"
                      ),
                    }}
                  />

                  <NavLink
                    to={WEB_ROUTES.DIVISION_PRASA_CRES}
                    className="flex text-[#25374f] text-xl md:float-start"
                  >
                    <span>More about PRASA Cres</span>
                    <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
                  </NavLink>
                </div>
              ) : null}
            </div>
            <div className="hidden lg:block md:w-1/2 px-10 py-5">
              <img
                src="images/prasa-icon-white.svg"
                className="w-40 h-auto"
                alt=""
              />
            </div>
          </div>

          <div className="container mx-auto">
            <div className="lg:w-full flex content-center pt-10 px-0">
              <div className="lg:flex">
                <>
                  <div className="lg:w-2/4 text-white">
                    <h2
                      className="bg-[#72bbe8] text-3xl font-medium lg:px-10 px-8 lg:py-0 py-8 h-20 flex items-center"
                      dangerouslySetInnerHTML={{
                        __html: keyDivisionPage?.title?.rendered,
                      }}
                    />

                    <div
                      className="lg:p-10 p-8 bg-[#166f97]"
                      dangerouslySetInnerHTML={{
                        __html: keyDivisionPage?.content?.rendered?.replace(
                          /<p>/g,
                          "<p className='mb-4'>"
                        ),
                      }}
                    />
                  </div>
                  <div className="lg:w-2/4 flex flex-col">
                    <div className="bg-white h-20 flex">
                      {divisions.map((division, i) => (
                        <div
                          key={i}
                          onClick={() => goToSlide(i)}
                          className={`w-1/3 px-3 py-3 hover:bg-neutral-200 hover:cursor-pointer h-full flex items-center justify-center ${
                            keyDivision === i && "bg-neutral-200"
                          }`}
                        >
                          <img
                            className={`${
                              i === 3 || i === 4
                                ? "h-full w-auto"
                                : "w-full h-auto"
                            }`}
                            src={
                              divisionsLogos?.find(
                                (media) => media.id === division?.featured_media
                              )?.source_url
                            }
                            alt={division?.title?.rendered}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="relative bg-[#25374f] grow lg:min-h-0 min-h-80">
                      <div className="division-slider absolute z-10 h-full w-full top-0 left-0">
                        <Swiper
                          ref={divisionSliderRef}
                          onSlideChange={(swiper) => {
                            setKeyDivision(swiper.realIndex);
                          }}
                          modules={[
                            Navigation,
                            Pagination,
                            Scrollbar,
                            A11y,
                            Autoplay,
                          ]}
                          slidesPerView={1}
                          navigation
                          direction={"horizontal"}
                          loop={true}
                          autoplay={{
                            delay: 5000,
                          }}
                        >
                          {divisions.map((division, i) => (
                            <SwiperSlide key={i}>
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    divisionsMedia?.find(
                                      (media) =>
                                        media.id ===
                                        division?.acf?.image_for_home_page
                                    )?.source_url
                                  })`,
                                }}
                                className="absolute top-0 left-0 z-40 h-full w-full bg-no-repeat xl:bg-[length:100%_auto] lg:bg-[length:auto_100%] md:bg-[length:100%_auto] bg-[length:auto_100%] bg-center"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <div
                          onClick={handleDivisionSliderPrev}
                          className="swiper-button-prev px-6 py-10 bg-transparent]"
                        >
                          <i className="fi fi-br-angle-left text-4xl text-white inline-block mt-2"></i>
                        </div>
                        <div
                          onClick={handleDivisionSliderNext}
                          className="swiper-button-next px-6 py-10 bg-transparent"
                        >
                          <i className="fi fi-br-angle-right text-4xl text-white inline-block mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </section>

        <section className="relative md:pt-0 pt-16">
          <div className="absolute top-0 right-0 bg-[#46515e] w-1/2 h-full -z-10 md:block hidden"></div>
          <div className="container mx-auto">
            <div className="md:flex">
              <div
                className="md:w-1/2 md:min-h-0 w-full min-h-60 bg-no-repeat bg-center bg-cover "
                style={{
                  backgroundImage: `url(${trainSchedulesMedia?.source_url})`,
                }}
              ></div>
              <div className="md:w-1/2 w-full bg-[#46515e] text-white md:p-16 md:pr-10 px-8 pt-8 pb-16">
                <h2 className="font-medium mb-4 text-4xl text-[#06a7e0]">
                  {trainSchedules?.acf?.train_schedule_cta?.title ? (
                    <NavLink to={trainSchedules?.acf?.train_schedule_cta?.url}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: trainSchedules?.title?.rendered,
                        }}
                      />
                    </NavLink>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: trainSchedules?.title?.rendered,
                      }}
                    />
                  )}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: trainSchedules?.content?.rendered?.replace(
                      /<p>/g,
                      "<p className='mb-4'>"
                    ),
                  }}
                />
                {trainSchedules?.acf?.train_schedule_cta?.title ? (
                  <NavLink
                    to={trainSchedules?.acf?.train_schedule_cta?.url}
                    className="flex text-white bg-[#ceb596] px-5 py-2 mt-8 float-start rounded-full"
                  >
                    <span>
                      {trainSchedules?.acf?.train_schedule_cta?.title}
                    </span>
                  </NavLink>
                ) : null}

                <img
                  className="md:mt-8 mt-4 w-72 h-auto md:float-end float-start"
                  src="/images/metrorail-a-division-of-prasa.svg"
                  alt="Metrorail"
                />
                <div className="clear-both"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative ">
          <div className="container mx-auto border-b-2">
            <div className="lg:flex">
              <img
                className="w-full h-auto block lg:hidden"
                src={
                  prasaCresMedia?.find(
                    (media) => media?.id === prasaCres?.acf?.mobile_image
                  )?.source_url
                }
                alt=""
              />
              <div className="lg:w-1/2 w-full xl:p-16 2xl:py-32 xl:pl-0 p-8">
                <h2 className="font-medium mb-4 text-4xl text-[#06a7e0]">
                  <NavLink to={WEB_ROUTES.DIVISION_PRASA_CRES}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: prasaCres?.title?.rendered,
                      }}
                    />
                  </NavLink>
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: prasaCres?.content?.rendered?.replace(
                      /<p>/g,
                      "<p className='mb-4'>"
                    ),
                  }}
                />

                <NavLink
                  to={WEB_ROUTES.DIVISION_PRASA_CRES}
                  className="flex text-white bg-[#06a7e0] px-5 py-2 mt-10 float-start rounded-full"
                >
                  More about PRASA Cres
                </NavLink>

                <div className="clear-both"></div>
              </div>
              <div
                className="hidden lg:block lg:w-1/2 lg:min-h-0 w-full min-h-60 bg-no-repeat bg-[left_0px_bottom_0px] bg-[length:100%_auto]"
                style={{
                  backgroundImage: `url(${
                    prasaCresMedia?.find(
                      (media) => media?.id === prasaCres?.featured_media
                    )?.source_url
                  })`,
                }}
              ></div>
            </div>
          </div>
        </section>

        <section className="bg-white py-10 md:py-16">
          <div className="mx-auto container xl:px-0 px-8">
            <div className="mx-full">
              <h2 className="text-3xl font-medium float-left">
                Latest News and Media
              </h2>

              <NavLink
                to={WEB_ROUTES.NEWS}
                className="hidden md:flex float-right mt-0.5 text-[#06a7e0] text-xl"
              >
                <span>All News and Media</span>
                <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
              </NavLink>
              <div className="clear-both"></div>
            </div>
            <div className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts?.slice(0, 3).map((article, i) => (
                <Fragment key={i}>
                  <NewsItem article={article} />
                </Fragment>
              ))}
            </div>
            <div className="mx-full md:pt-0 pt-10">
              <NavLink
                to={WEB_ROUTES.NEWS}
                className="text-xl flex md:float-right md:hidden text-[#06a7e0]"
              >
                <span>All News and Media</span>
                <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
              </NavLink>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-10 md:pb-16">
          <div className="mx-auto container px-0 ">
            <NavLink to={WEB_ROUTES.BLOW_A_WHISTLE}>
              <img
                className="w-full h-auto hidden md:block"
                src={
                  whistleBlowingPageMedia?.find(
                    (media) => media?.id === whistleBlowingPage?.featured_media
                  )?.source_url
                }
                alt="Advert PRASA Blow a whistle"
              />
            </NavLink>

            <NavLink to={WEB_ROUTES.BLOW_A_WHISTLE}>
              <img
                className="w-full h-auto block md:hidden"
                src={
                  whistleBlowingPageMedia?.find(
                    (media) =>
                      media?.id === whistleBlowingPage?.acf?.mobile_image
                  )?.source_url
                }
                alt="Advert PRASA Blow a whistle"
              />
            </NavLink>
          </div>
        </section>
        {Object.keys(project).length ? (
          <section className="relative md:pb-0 pb-16">
            <div className="absolute top-0 left-0 bg-[#06a7e0] md:w-1/2 w-full h-full -z-10"></div>

            <div
              className="md:w-1/2 w-full h-full bg-no-repeat bg-center bg-cover md:absolute right-0 top-0 md:min-h-0 min-h-60"
              style={{
                backgroundImage: `url(${projectMedia?.source_url})`,
              }}
            ></div>

            <div className="md:flex container mx-auto">
              <div className="md:w-1/2 w-full text-white md:py-16 md:pr-16 xl:pl-0 p-8">
                <h3 className="text-neutral-700">Featured PRASA Project</h3>

                <h2 className="font-medium mb-4 text-4xl">
                  <NavLink
                    to={WEB_ROUTES.PROJECTS + "/" + project?.slug}
                    dangerouslySetInnerHTML={{
                      __html: project?.title?.rendered,
                    }}
                  />
                </h2>

                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: project?.excerpt?.rendered,
                    }}
                  />
                </div>
                <NavLink
                  to={WEB_ROUTES.PROJECTS + "/" + project?.slug}
                  className="flex text-neutral-700 hover:text-neutral-800 font-medium text-xl md:mt-10 mt-5 float-start"
                >
                  <span>Learn more</span>
                  <i className="text-xl ml-2 mt-1 fi fi-br-angle-small-right"></i>
                </NavLink>
              </div>
            </div>
          </section>
        ) : null}

        <section className="py-10 xl:px-0 px-8 bg-white">
          <DivisionsScroller title={"PRASA Divisions and Subsidiaries"} />
        </section>
      </main>
      <Footer />
    </>
  );
}
