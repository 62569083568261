import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import SocialIcons from "./social-icons";

export default function Header() {
  const location = useLocation();

  return (
    <header className="bg-[#06a7e0] relative text-white py-4">
      <div className="absolute bottom-0 left-0 w-full h-5 shadow-lg z-40"></div>
      <nav
        className="container mx-auto md:flex items-center justify-between xl:py-4 md:pb-6 pb-2 md:px-0 px-8 md:space-y-0 space-y-4"
        aria-label="Global"
      >
        <div className="flex md:flex-1 items-center">
          <NavLink
            to={WEB_ROUTES.HOME}
            className="inline-block md:w-48 w-36 h-auto"
          >
            <span className="sr-only">PRASA Logo</span>
            <img src="/images/prasa-logo-white.svg" alt="PRASA Logo" />
          </NavLink>

          <div className="inline-block md:hidden ml-auto">
            <label
              className="relative z-40 cursor-pointer px-3 py-6"
              htmlFor="mobile-menu"
            >
              <input className="peer hidden" type="checkbox" id="mobile-menu" />
              <div className="relative z-50 block h-[1px] w-7 bg-white bg-transparent content-[''] before:absolute before:top-[-0.35rem] before:z-50 before:block before:h-full before:w-full before:bg-white before:transition-all before:duration-200 before:ease-out before:content-[''] after:absolute after:right-0 after:bottom-[-0.35rem] after:block after:h-full after:w-full after:bg-white after:transition-all after:duration-200 after:ease-out after:content-[''] peer-checked:bg-transparent before:peer-checked:top-0 before:peer-checked:w-full before:peer-checked:rotate-45 before:peer-checked:transform after:peer-checked:bottom-0 after:peer-checked:w-full after:peer-checked:-rotate-45 after:peer-checked:transform"></div>
              <div className="fixed inset-0 z-40 hidden h-full w-full bg-black/50 backdrop-blur-sm peer-checked:block">
                &nbsp;
              </div>
              <div className="fixed top-0 right-0 z-40 h-full w-full translate-x-full overflow-y-auto overscroll-y-none transition duration-500 peer-checked:translate-x-0">
                <div className="float-right min-h-full w-[85%] bg-[#06a7e0] px-10 py-6 shadow-2xl">
                  <NavLink className="inline-block" to={WEB_ROUTES.HOME}>
                    <img
                      className=" w-40 h-auto"
                      src="/images/prasa-logo-white.svg"
                      alt="PRASA Logo"
                    />
                  </NavLink>
                  <menu className="mt-8 mb-6 flex flex-col space-y-6 text-heading">
                    <NavLink
                      to={WEB_ROUTES.HOME}
                      className={`font-medium hover:text-[#46515e] ${
                        WEB_ROUTES.HOME === location.pathname &&
                        "text-[#46515e]"
                      }`}
                    >
                      Home
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.ABOUT}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.ABOUT) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      About
                    </NavLink>
                    <NavLink
                      to={WEB_ROUTES.RAIL_OPERATIONS}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(
                          WEB_ROUTES.RAIL_OPERATIONS
                        ) !== -1 && "text-[#46515e]"
                      }`}
                    >
                      Rail Operations
                    </NavLink>
                    <NavLink
                      to={WEB_ROUTES.REAL_ESTATE}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.REAL_ESTATE) !==
                          -1 && "text-[#46515e]"
                      }`}
                    >
                      Real Estate
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.PROCUREMENT}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.PROCUREMENT) !==
                          -1 && "text-[#46515e]"
                      }`}
                    >
                      Procurement
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.CAREERS}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.CAREERS) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      Careers
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.PUBLICATIONS}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.PUBLICATIONS) !==
                          -1 && "text-[#46515e]"
                      }`}
                    >
                      Publications
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.NEWS}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.NEWS) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      News
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.PROJECTS}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.PROJECTS) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      Projects
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.GALLERY}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.GALLERY) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      Gallery
                    </NavLink>

                    <NavLink
                      to={WEB_ROUTES.CONTACT}
                      className={`font-medium hover:text-[#46515e] ${
                        location.pathname.indexOf(WEB_ROUTES.CONTACT) !== -1 &&
                        "text-[#46515e]"
                      }`}
                    >
                      Contact
                    </NavLink>
                  </menu>
                  <div>
                    <a className="flex mb-3" href="tel:+27110131667">
                      <svg
                        className="w-6 h-6 mr-2 mt-1 inline-block"
                        id="fi_5585846"
                        fill="#ffffff"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                      >
                        <path
                          d="m256 0c141.385 0 256 114.615 256 256s-114.615 256-256 256-256-114.615-256-256 114.615-256 256-256zm150.3 374.436a19.622 19.622 0 0 0 0-27.678l-50.111-50.1a19.63 19.63 0 0 0 -27.69 0l-13.617 13.623a29.8 29.8 0 0 1 -35.245 5.279 200.184 200.184 0 0 1 -83.193-83.183 29.82 29.82 0 0 1 5.27-35.257l13.643-13.62a19.631 19.631 0 0 0 0-27.685l-50.111-50.095a19.629 19.629 0 0 0 -27.691 0c-2.071 2.065-4.691 4.56-7.493 7.2-7.007 6.623-15.749 14.866-19.283 20.048-18.613 27.239-9.687 63.681 1.036 89.459 14.165 33.977 40.271 71 73.536 104.242 33.235 33.238 70.246 59.347 104.242 73.512 25.772 10.738 62.2 19.642 89.438 1.033 5.179-3.537 13.434-12.258 20.044-19.274 2.651-2.797 5.148-5.44 7.225-7.504z"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                      <span>+27 11 013 6700</span>
                    </a>

                    <a className="flex mb-3" href="mailto:info@prasa.com">
                      <svg
                        className="w-6 h-6 mr-2 mt-1 inline-block"
                        id="fi_3178158"
                        fill="#ffffff"
                        enable-background="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="m256 512c-141.159 0-256-114.841-256-256s114.841-256 256-256 256 114.841 256 256-114.841 256-256 256zm132.758-166.069c3.156 0 5.725-2.568 5.725-5.725v-160.717l-130.25 103.432c-2.41 1.915-5.323 2.872-8.234 2.872s-5.823-.958-8.234-2.872l-130.249-103.432v160.717c0 3.157 2.569 5.725 5.725 5.725zm-19.96-179.862-112.798 89.575-112.799-89.575zm52.168 5.725v168.414c0 17.759-14.449 32.208-32.208 32.208h-265.517c-17.76 0-32.207-14.449-32.207-32.208v-168.414c0-17.759 14.448-32.207 32.207-32.207h265.517c17.759-.001 32.208 14.448 32.208 32.207z"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                      <span>info@prasa.com</span>
                    </a>
                  </div>
                  <div className="flex gap-x-3 mt-8">
                    <SocialIcons />
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div className="hidden md:flex gap-x-4 xl:text-lg text-base">
          <a href="tel:+27110131667">
            <svg
              className="w-6 h-6 mr-2 mt-1 inline-block float-left"
              id="fi_5585846"
              fill="#ffffff"
              height="512"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
            >
              <path
                d="m256 0c141.385 0 256 114.615 256 256s-114.615 256-256 256-256-114.615-256-256 114.615-256 256-256zm150.3 374.436a19.622 19.622 0 0 0 0-27.678l-50.111-50.1a19.63 19.63 0 0 0 -27.69 0l-13.617 13.623a29.8 29.8 0 0 1 -35.245 5.279 200.184 200.184 0 0 1 -83.193-83.183 29.82 29.82 0 0 1 5.27-35.257l13.643-13.62a19.631 19.631 0 0 0 0-27.685l-50.111-50.095a19.629 19.629 0 0 0 -27.691 0c-2.071 2.065-4.691 4.56-7.493 7.2-7.007 6.623-15.749 14.866-19.283 20.048-18.613 27.239-9.687 63.681 1.036 89.459 14.165 33.977 40.271 71 73.536 104.242 33.235 33.238 70.246 59.347 104.242 73.512 25.772 10.738 62.2 19.642 89.438 1.033 5.179-3.537 13.434-12.258 20.044-19.274 2.651-2.797 5.148-5.44 7.225-7.504z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span className="float-left mt-1">+27 11 013 6700</span>
            <div className="clear-both"></div>
          </a>

          <a className="flex" href="mailto:info@prasa.com">
            <svg
              className="w-6 h-6 mr-2 mt-1 inline-block float-left"
              id="fi_3178158"
              fill="#ffffff"
              enable-background="new 0 0 512 512"
              height="512"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="m256 512c-141.159 0-256-114.841-256-256s114.841-256 256-256 256 114.841 256 256-114.841 256-256 256zm132.758-166.069c3.156 0 5.725-2.568 5.725-5.725v-160.717l-130.25 103.432c-2.41 1.915-5.323 2.872-8.234 2.872s-5.823-.958-8.234-2.872l-130.249-103.432v160.717c0 3.157 2.569 5.725 5.725 5.725zm-19.96-179.862-112.798 89.575-112.799-89.575zm52.168 5.725v168.414c0 17.759-14.449 32.208-32.208 32.208h-265.517c-17.76 0-32.207-14.449-32.207-32.208v-168.414c0-17.759 14.448-32.207 32.207-32.207h265.517c17.759-.001 32.208 14.448 32.208 32.207z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span className="float-left mt-1">info@prasa.com</span>
            <div className="clear-both"></div>
          </a>
        </div>

        <div className="hidden md:flex gap-x-2 ml-5">
          <SocialIcons />
        </div>

        {/* <div className="flex gap-x-4 ml-16">
          <a href="#.">Register</a>
          {"/"}
          <a href="#." className="flex">
            <span>Login</span>
            <svg
              className="w-5 h-5 ml-2 mt-1 inline-block"
              fill="#ffffff"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_8944312"
            >
              <g id="ARROW_48" data-name="ARROW 48">
                <path d="m400.14.16h-218.79a82 82 0 0 0 -81.9 81.9v50.54a24 24 0 1 0 48 0v-50.54a33.94 33.94 0 0 1 33.9-33.9h218.79a33.93 33.93 0 0 1 33.86 33.9v347.88a33.93 33.93 0 0 1 -33.89 33.9h-218.76a33.94 33.94 0 0 1 -33.9-33.9v-58.47a24 24 0 0 0 -48 0v58.47a82 82 0 0 0 81.9 81.9h218.79a82 82 0 0 0 81.86-81.9v-347.88a82 82 0 0 0 -81.86-81.9z"></path>
                <path d="m364.64 238.53-85.4-85.35a24 24 0 0 0 -33.61-.33c-9.7 9.33-9.47 25.13.05 34.65l44.47 44.5h-236.15a24 24 0 0 0 -24 24 24 24 0 0 0 24 24h236.16l-44.9 44.9a24 24 0 0 0 33.94 33.95l85.44-85.41a24.66 24.66 0 0 0 0-34.91z"></path>
              </g>
            </svg>
          </a>
          
        </div> */}
      </nav>

      <nav className="hidden container mx-auto md:flex items-center justify-between pb-4 px-0 xl:text-lg lg:text-base text-sm">
        <div className="flex sm:flex-1 xl:gap-x-4 lg:gap-x-3 gap-x-[5px] justify-end">
          <NavLink
            to={WEB_ROUTES.HOME}
            className={`font-medium hover:text-[#46515e] ${
              WEB_ROUTES.HOME === location.pathname && "text-[#46515e]"
            }`}
          >
            Home
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.ABOUT}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.ABOUT) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            About
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.RAIL_OPERATIONS}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.RAIL_OPERATIONS) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Rail Operations
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.REAL_ESTATE}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.REAL_ESTATE) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Real Estate
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.PROCUREMENT}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.PROCUREMENT) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Procurement
          </NavLink>

          {"/"}
          <NavLink
            to={WEB_ROUTES.CAREERS}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.CAREERS) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Careers
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.PUBLICATIONS}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.PUBLICATIONS) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Publications
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.NEWS}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.NEWS) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            News
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.PROJECTS}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.PROJECTS) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Projects
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.GALLERY}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.GALLERY) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Gallery
          </NavLink>
          {"/"}
          <NavLink
            to={WEB_ROUTES.CONTACT}
            className={`font-medium hover:text-[#46515e] ${
              location.pathname.indexOf(WEB_ROUTES.CONTACT) !== -1 &&
              "text-[#46515e]"
            }`}
          >
            Contact
          </NavLink>
        </div>
      </nav>
    </header>
  );
}
