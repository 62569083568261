export default function ProcurementItem({ tender, procurementMedia, i }) {
  return (
    <>
      <div
        className={`${
          i % 2 === 0 ? "bg-[#e0e0e2]" : "bg-white"
        } w-full py-10 px-10`}
      >
        <div className="mb-4">
          <a
            href={
              procurementMedia?.find(
                (media) => media?.id === tender?.acf?.tender_document
              )?.source_url
            }
            className="text-[#06a7e0] text-xl font-semibold hover:underline hover:cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: "REF NUMBER: " + tender?.title?.rendered,
            }}
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: tender?.content?.rendered,
          }}
        />

        <p>
          <span className="font-semibold">Region:</span>{" "}
          {tender?.acf?.tender_region}
        </p>
        <p>
          <span className="font-semibold">Compulsory Briefing Session:</span>{" "}
          {tender?.acf?.compulsory_briefing_session}
        </p>

        <p>
          <span className="font-semibold">Closing Date:</span>{" "}
          {tender?.acf?.closing_date}
        </p>
        {procurementMedia?.find(
          (media) => media?.id === tender?.acf?.tender_document
        )?.source_url ? (
          <a
            href={
              procurementMedia?.find(
                (media) => media?.id === tender?.acf?.tender_document
              )?.source_url
            }
            className="mt-4 flex text-[#25374f] hover:text-[#06a7e0] float-start"
            target="_blank"
            rel="noreferrer"
          >
            <i className="mr-1 mt-0.5 fi fi-sr-file-download text-[#06a7e0]"></i>
            <span>View full document</span>
            <i className="ml-2 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
          </a>
        ) : null}
        <div className="clear-both"></div>
      </div>
    </>
  );
}
