import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function GalleryItem({ article, galleryMedia }) {
  return (
    <>
      <div className="flex flex-col items-start ">
        <NavLink
          to={WEB_ROUTES.GALLERY + `/` + article?.slug}
          className="relative w-full md:pt-0 pt-60 bg-no-repeat bg-center bg-cover md:!bg-none"
          style={{
            backgroundImage: `url(${galleryMedia?.source_url})`,
          }}
        >
          <div className="overflow-hidden md:inline-block hidden h-full">
            <img
              src={galleryMedia?.source_url}
              alt={article?.title?.rendered}
              className="aspect-[16/9] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0)",
                zIndex: 1,
              }}
            />
          </div>
        </NavLink>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-6">
              <NavLink
                className="text-2xl text-[#06a7e0] hover:underline"
                to={WEB_ROUTES.GALLERY + `/` + article?.slug}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: article?.title?.rendered,
                  }}
                />
              </NavLink>
            </h3>

            <p
              className="text-gray-600"
              dangerouslySetInnerHTML={{
                __html: article?.excerpt?.rendered,
              }}
            />

            <NavLink
              to={WEB_ROUTES.GALLERY + `/` + article?.slug}
              className="mt-5 flex hover:text-[#06a7e0] float-start"
            >
              <span>View gallery</span>
              <i className="text-xl ml-1 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
