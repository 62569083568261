import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { useMatch } from "react-router-dom";

export default function JobsSingle() {
  const singleJob = useMatch(WEB_ROUTES.JOBS + "/:job");

  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [job, setJob] = useState([]);
  let { getMetaSnippet } = useUtilities();
  const [jobMedia, setJobMedia] = useState([]);

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "careers"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    //Get job
    axios
      .get(CONTENT_ROUTES.JOBS("?slug=" + singleJob?.params?.job))
      .then((res) => {
        let jobResponse = res.data?.length ? res.data[0] : {};
        setJob(jobResponse);

        //Get project media
        axios.get(CONTENT_ROUTES.MEDIA_BY_PAGE(jobResponse?.id)).then((res) => {
          setJobMedia(res.data);
        });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleJob]);

  return (
    <>
      <Helmet>
        <title>{job?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(job?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <h1
              className="pb-10 text-4xl font-medium xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: job?.title?.rendered,
              }}
            />
          </div>
        </section>

        <section className="relative">
          <div
            className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat bg-[left_-200px_top_0px] bg-[length:auto_100%]"
            style={{
              backgroundImage: `url(${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              })`,
            }}
          ></div>

          <div className="container mx-auto xl:px-0 px-8">
            <div className="md:flex md:justify-end">
              <div className="md:w-9/12 md:pr-16 bg-white">
                <article className="md:p-10 py-10">
                  <h2 className="wp-block-heading">JOB DETAILS</h2>
                  <p>
                    <span className="font-semibold">Salary Group:</span>{" "}
                    {job?.acf?.salary_group}
                  </p>
                  <p>
                    <span className="font-semibold">Location:</span>{" "}
                    {job?.acf?.location}
                  </p>
                  <p>
                    <span className="font-semibold">Reference:</span>{" "}
                    {job?.acf?.reference}
                  </p>
                  <p>
                    <span className="font-semibold">Closing Date:</span>{" "}
                    {job?.acf?.closing_date}
                  </p>
                  <div
                    className="mt-5"
                    dangerouslySetInnerHTML={{
                      __html: job?.content?.rendered?.replace(
                        /<p.*?>/g,
                        "<p class='mb-5'>"
                      ),
                    }}
                  />

                  <a
                    href={
                      jobMedia?.find(
                        (media) => media?.id === job?.acf?.full_job_descriptions
                      )?.source_url
                    }
                    className="mt-4 flex text-[#212122] hover:text-[#06a7e0] float-start"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="mr-1 mt-0.5 fi fi-sr-file-download text-[#06a7e0]"></i>
                    <span>Download full job document</span>
                  </a>

                  <a
                    href={
                      `mailto:${job?.acf?.job_application_email}?subject=Job application for: ` +
                      job?.title?.rendered
                    }
                    className="mt-4 flex text-[#25374f] hover:text-[#06a7e0] float-end"
                    rel="noreferrer"
                  >
                    <span>Apply for Job</span>
                    <i className="ml-2 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
                  </a>

                  <div className="clear-both"></div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
