import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import ProjectItem from "../components/project-item";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { WEB_ROUTES } from "../routes/web-routes";
import { useMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Projects() {
  const [activeProject, setActiveProject] = useState("all");
  const projectType = useMatch(WEB_ROUTES.PROJECTS_TYPE + "/:type");

  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [projectsMedia, setProjectsMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "projects"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get projects
        axios
          .get(
            CONTENT_ROUTES.PROJECTS(
              "?order_by=menu_order&order=asc&per_page=100"
            )
          )
          .then((res) => {
            setAllProjects(res.data);

            //Get projects media
            let media = [];

            res.data?.map((project, i) => {
              if (project?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(project?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setProjectsMedia((prevState) => [...prevState, media[i]]);
                  });
              return project?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (activeProject !== "all")
      setProjects(
        allProjects.filter(
          (project) =>
            project?.acf?.project_status?.toLowerCase() === activeProject
        )
      );
    else setProjects(allProjects);
  }, [activeProject, allProjects]);

  useEffect(() => {
    if (projectType?.params?.type && projectType?.params?.type?.length !== 0)
      setActiveProject(projectType?.params?.type);
    else setActiveProject("all");
  }, [projectType]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <div className="lg:flex">
              <div className="lg:w-3/12">
                <h1 className="pb-5 text-4xl font-semibold xl:px-0 px-8">
                  {page?.title?.rendered}
                </h1>
              </div>
              <div className="lg:w-9/12 lg:px-16 pb-10">
                <div
                  className="xl:px-0 px-8"
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
                {/* <div className="container mx-auto flex bg-[#46515e]">
                  <NavLink
                    to={WEB_ROUTES.PROJECTS}
                    className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block ${
                      activeProject === "all"
                        ? "bg-[#626873] text-white"
                        : "hover:bg-[#626873] hover:text-white"
                    }`}
                  >
                    All projects
                  </NavLink>
                  <NavLink
                    to={WEB_ROUTES.PROJECTS_TYPE + "/completed"}
                    className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block ${
                      activeProject === "completed"
                        ? "bg-[#626873] text-white"
                        : "hover:bg-[#626873] hover:text-white"
                    }`}
                  >
                    Completed projects
                  </NavLink>
                  <NavLink
                    to={WEB_ROUTES.PROJECTS_TYPE + "/ongoing"}
                    className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block ${
                      activeProject === "ongoing"
                        ? "bg-[#626873] text-white"
                        : "hover:bg-[#626873] hover:text-white "
                    }`}
                  >
                    Ongoing projects
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div
            className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat bg-[right_0px_top_0px] bg-[length:auto_100%]"
            style={{
              backgroundImage: `url(${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              })`,
            }}
          ></div>
          <div className="container mx-auto xl:px-0 px-8">
            <div className="md:flex justify-end">
              <div className="md:w-9/12 md:p-16 md:pt-10 py-10 bg-white">
                <h2 className="text-3xl font-medium mb-10 text-neutral-700">
                  {activeProject.charAt(0).toUpperCase() +
                    activeProject.slice(1)}{" "}
                  Projects
                </h2>

                <div className="mx-auto grid lg:grid-cols-2 grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 ">
                  {projects?.map((project, i) => (
                    <Fragment key={i}>
                      <ProjectItem
                        project={project}
                        projectMedia={
                          projectsMedia?.find(
                            (media) => media?.id === project?.featured_media
                          )?.source_url
                        }
                      />
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
