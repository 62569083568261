import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import GalleryItem from "../components/gallery-item";
import { Helmet } from "react-helmet";

export default function Gallery() {
  const [gallery, setGallery] = useState([]);
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [galleryMedia, setGalleryMedia] = useState([]);

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "gallery"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get gallery
        axios
          .get(
            CONTENT_ROUTES.GALLERY(
              "?order_by=menu_order&order=desc&per_page=100"
            )
          )
          .then((res) => {
            setGallery(res.data);

            //Get gallery media
            let media = [];

            res.data?.map((project, i) => {
              if (project?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(project?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setGalleryMedia((prevState) => [...prevState, media[i]]);
                  });
              return project?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {});
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Gallery - PRASA`}</title>
        <meta
          name="description"
          content={`Explore PRASA's curated collection of images and videos that capture key moments, events, and experiences.`}
        />
      </Helmet>

      <Header />
      <main>
        <section className="bg-[#06a7e0] py-16 text-white">
          <div className="container mx-auto xl:px-0 px-8">
            <div className="text-base leading-7">
              <h1
                className="pb-5 text-4xl font-semibold"
                dangerouslySetInnerHTML={{
                  __html: page?.title?.rendered,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered,
                }}
              />
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto xl:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:grid-cols-3">
              {gallery?.map((article, i) => (
                <Fragment key={i}>
                  <GalleryItem
                    article={article}
                    galleryMedia={galleryMedia?.find(
                      (media) => media.id === article?.featured_media
                    )}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
