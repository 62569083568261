import { WEB_ROUTES } from "../routes/web-routes";

export default function RealEstateItem({ item, itemMedia }) {
  return (
    <>
      <div className="flex flex-col items-start ">
        <div
          to={WEB_ROUTES.PROJECTS + `/` + item?.slug}
          className="relative w-full md:pt-0 pt-60 bg-no-repeat bg-center bg-cover md:bg-none"
          style={{
            backgroundImage: `url(${itemMedia})`,
          }}
        >
          <div className="overflow-hidden md:inline-block hidden h-full">
            <img
              src={itemMedia}
              alt={item?.title?.rendered}
              className="aspect-[16/9] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="text-2xl text-[#06a7e0] mb-2 mt-4">
              <span
                dangerouslySetInnerHTML={{
                  __html: item?.title?.rendered,
                }}
              />
            </h3>
            <p>
              <span className="font-semibold">Province:</span>{" "}
              {item?.acf?.province}
            </p>

            <div
              className="text-gray-600 mb-4"
              dangerouslySetInnerHTML={{
                __html: item?.content?.rendered,
              }}
            />

            <div className="font-semibold">Features</div>
            <div
              className="text-gray-600 text-base"
              dangerouslySetInnerHTML={{
                __html: item?.acf?.features,
              }}
            />
            <div className="font-semibold">Contact Person</div>
            <div
              className="text-gray-600 text-base"
              dangerouslySetInnerHTML={{
                __html: item?.acf?.contacts?.replace(
                  /<p.*?>/g,
                  "<p class='mb-5'>"
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
