import { useState, useEffect } from "react";
import axios from "axios";
import { useMatch } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Page() {
  const slug = useMatch("/:slug/*");
  const pageSlugs = slug.pathname?.split("/")?.filter((str) => str !== "");
  const pageSlug = pageSlugs[pageSlugs.length - 1];
  const [page, setPage] = useState({});
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", pageSlug + `&_embed`))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
      })
      .catch((err) => {})
      .finally(() => {});
  }, [pageSlug]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] text-white">
          <div className="container mx-auto">
            <div className="mx-auto md:flex">
              <div className="w-full py-16">
                <div className="relative 2xl:px-0 px-8">
                  {page?.parent ? (
                    <p className="text-[#46515e] mb-4 font-semibold">
                      {slug?.params?.slug?.charAt(0)?.toUpperCase() +
                        slug?.params?.slug?.slice(1).replace("-", " ")}
                    </p>
                  ) : null}

                  <h1
                    className="text-4xl font-semibold text-white"
                    dangerouslySetInnerHTML={{
                      __html: page?.title?.rendered?.replace(
                        /<p.*?>/g,
                        "<p class='mb-5'>"
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute top-0 left-0 bg-[#06a7e0] w-full h-40 -z-10"></div>
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto lg:flex">
              <article className="lg:w-9/12 w-full md:p-16 px-8 py-16 bg-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
              </article>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
