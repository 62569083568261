import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function ProjectItem({ project, projectMedia }) {
  return (
    <>
      <div className="flex flex-col items-start ">
        <NavLink
          to={WEB_ROUTES.PROJECTS + `/` + project?.slug}
          className="relative w-full md:pt-0 pt-60 bg-no-repeat bg-center bg-cover md:bg-none"
          style={{
            backgroundImage: `url(${projectMedia})`,
          }}
        >
          <div className="overflow-hidden md:inline-block hidden h-full">
            <img
              src={projectMedia}
              alt={project?.title?.rendered}
              className="aspect-[16/9] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
          </div>

          {/* <div className="absolute bottom-0 right-0 bg-[#06a7e0]/60 py-2 px-4 ">
            <p className="text-white leading-5">
              {project?.acf?.project_status}
            </p>
          </div> */}
        </NavLink>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-6">
              <NavLink
                className="text-2xl text-[#06a7e0] hover:underline"
                to={WEB_ROUTES.PROJECTS + `/` + project?.slug}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: project?.title?.rendered,
                  }}
                />
              </NavLink>
            </h3>

            <p
              className="text-gray-600"
              dangerouslySetInnerHTML={{
                __html: project?.excerpt?.rendered,
              }}
            />

            <NavLink
              to={WEB_ROUTES.PROJECTS + `/` + project?.slug}
              className="mt-5 flex hover:text-[#06a7e0] float-start"
            >
              <span>Read more</span>
              <i className="text-xl ml-1 mt-1 fi fi-br-angle-small-right text-[#06a7e0]"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
