import { WEB_ROUTES } from "../routes/web-routes";
import { NavLink } from "react-router-dom";

export default function TeamItem({ member, memberMedia }) {
  return (
    <>
      <div className="group relative overflow-hidden text-center">
        <NavLink
          to={WEB_ROUTES.TEAM + "/" + member?.slug}
          className="w-80 h-80 rounded-full inline"
        >
          <img
            src={memberMedia?.source_url}
            alt={member?.title?.rendered}
            className="w-80 h-80 rounded-full m-auto object-cover"
          />
        </NavLink>

        <div className="w-full px-8 py-4">
          <h4 className="my-2 mb-0 font-medium">
            <NavLink to={WEB_ROUTES.TEAM + "/" + member?.slug}>
              {member?.title?.rendered}{" "}
            </NavLink>
          </h4>
          <p className=" text-base">{member?.acf?.team_member_designation}</p>

          <NavLink
            to={WEB_ROUTES.TEAM + "/" + member?.slug}
            className="flex text-[#06a7e0] justify-center"
          >
            <span>See full profile</span>
            <i className="mt-1 fi fi-br-angle-small-right"></i>
          </NavLink>
        </div>
      </div>
    </>
  );
}
