// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function DivisionsScroller({ title }) {
  return (
    <div className="mx-auto container ">
      {title ? <h2 className="text-3xl font-medium mb-8">{title}</h2> : null}
      <div className="flex">
        {/* <i className="text-7xl mt-2 mr-5 fi fi-br-angle-small-right text-[#06a7e0]"></i> */}
        <div className="logo-division-slider flex space-x-10 w-full">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={5}
            direction={"horizontal"}
            loop={true}
            autoplay={true}
            breakpoints={{
              //Window width is >= 320px
              320: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              //Window width is >= 480px
              480: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              //Window width is >= 640px
              640: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
            }}
          >
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_PRASA_RAIL}>
                <img
                  className="w-48 h-auto"
                  src="/images/prasa-rail-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_PRASA_CRES}>
                <img
                  className="w-48 h-auto"
                  src="/images/prasa-cres-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_PRASA_TECH}>
                <img
                  className="w-48 h-auto"
                  src="/images/prasa-tech-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>

            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_AUTOPAX}>
                <img
                  className="h-20 w-auto"
                  src="/images/autopax-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_INTERSITE}>
                <img
                  className="h-20 w-auto"
                  src="/images/intersite-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_METRORAIL}>
                <img
                  className="h-20 w-auto"
                  src="/images/metrorail-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_SHOSHOLOZA}>
                <img
                  className="h-20 w-auto"
                  src="/images/shosholoza-meyl.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_PREMIER}>
                <img
                  className="w-36 h-auto"
                  src="/images/premier-classe.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_CITYTOCITY_TRANSLUX}>
                <img
                  className="w-40 h-auto"
                  src="/images/city-to-city.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
            <SwiperSlide>
              <NavLink to={WEB_ROUTES.DIVISION_CITYTOCITY_TRANSLUX}>
                <img
                  className="w-40 h-auto"
                  src="/images/translux-logo.svg"
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
