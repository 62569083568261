import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import ProcurementItem from "../components/procurement-item";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";
import { useMatch } from "react-router-dom";

export default function Procurement() {
  const location = useLocation();
  const procurementType = useMatch(WEB_ROUTES.PROCUREMENT + "/:type");

  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [procurement, setProcurement] = useState([]);
  const [allProcurement, setAllProcurement] = useState([]);
  let { getMetaSnippet } = useUtilities();
  const [procurementMedia, setProcurementMedia] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "procurement"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (procurementType?.params?.type === "tender-archives")
      getProcurement("tender-archives");
    else if (procurementType?.params?.type === "tenders-awarded") {
      axios
        .get(CONTENT_ROUTES.POST_PAGE("/pages", "tenders-awarded"))
        .then((res) => {
          const pageResponse = res.data?.length ? res.data[0] : {};
          setProcurement(pageResponse);

          //Get page media
          axios
            .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
            .then((res) => {
              setProcurementMedia(res.data);
            });
        });
    } else if (procurementType?.params?.type === "tenders-non-awarded") {
      axios
        .get(CONTENT_ROUTES.POST_PAGE("/pages", "non-awarded"))
        .then((res) => {
          const pageResponse = res.data?.length ? res.data[0] : {};
          setProcurement(pageResponse);

          //Get page media
          axios
            .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
            .then((res) => {
              setProcurementMedia(res.data);
            });
        });
    } else getProcurement("tender");

    setFilter("");
    setFilterBy("");
  }, [procurementType]);

  const getProcurement = (type) => {
    axios
      .get(
        CONTENT_ROUTES.PROCUREMENT(
          type,
          "?order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setProcurement(res.data);
        setAllProcurement(res.data); // Store the original list

        //Get publications media
        let media = [];

        res.data?.map((procurement, i) => {
          if (procurement?.acf?.tender_document)
            axios
              .get(
                CONTENT_ROUTES.MEDIA_BY_ID(procurement?.acf?.tender_document)
              )
              .then((res) => {
                media[i] = res.data;
                setProcurementMedia((prevState) => [...prevState, media[i]]);
              });

          return procurement?.acf?.tender_document;
        });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    setProcurement(
      filter && filterBy
        ? allProcurement.filter(
            (tender) =>
              tender?.acf?.tender_region?.includes(filterBy) &&
              tender?.title?.rendered?.toLowerCase()?.includes(filter)
          )
        : filterBy
        ? allProcurement.filter((tender) =>
            tender?.acf?.tender_region?.includes(filterBy)
          )
        : allProcurement.filter((tender) =>
            tender?.title?.rendered?.toLowerCase()?.includes(filter)
          )
    );

    if (!filter && !filterBy) {
      setProcurement(allProcurement); // Reset to original list if input is cleared
    }
  }, [filter, filterBy, allProcurement]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <h1 className="pb-5 text-4xl font-semibold xl:px-0 px-8">
              {page?.title?.rendered}
            </h1>
            <div
              className="xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered?.replace(
                  /<p.*?>/g,
                  "<p class='mb-5'>"
                ),
              }}
            />
            <div className="mt-10 container mx-auto  flex  bg-[#46515e]">
              <NavLink
                to={WEB_ROUTES.PROCUREMENT + "/tenders"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  procurementType?.params?.type === undefined ||
                  procurementType?.params?.type === null ||
                  procurementType?.params?.type === "tenders"
                    ? "bg-[#626873]"
                    : ""
                }`}
              >
                Tenders
              </NavLink>
              <NavLink
                to={WEB_ROUTES.PROCUREMENT + "/tender-archives"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("tender-archives") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Tender Archives
              </NavLink>
              <NavLink
                to={WEB_ROUTES.PROCUREMENT + "/tenders-awarded"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("tenders-awarded") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Tenders Awarded
              </NavLink>
              <NavLink
                to={WEB_ROUTES.PROCUREMENT + "/tenders-non-awarded"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("tenders-non-awarded") !== -1 &&
                  "bg-[#626873]"
                }`}
              >
                Non-Awarded
              </NavLink>
            </div>
          </div>
        </section>

        <section className="relative">
          <div
            className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat xl:bg-[left_-200px_top_0px] bg-[center_top_0px] bg-[length:auto_100%]"
            style={{
              backgroundImage: `url(${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              })`,
            }}
          ></div>

          <div className="container mx-auto xl:px-0 px-8">
            <div className="md:flex md:justify-end">
              <div className="md:w-9/12 xl:pr-10 px-0 bg-white min-h-96">
                <div className="py-10">
                  <h2 className="text-3xl font-medium md:px-10 text-neutral-700 xl:float-start first-letter:uppercase capitalize">
                    {procurementType?.params?.type
                      ? procurementType?.params?.type?.replace("-", " ")
                      : "tenders"}{" "}
                    {procurementType?.params?.type !== "tenders-awarded" &&
                    procurementType?.params?.type !== "tenders-non-awarded" ? (
                      <>({procurement?.length})</>
                    ) : null}
                  </h2>

                  {procurementType?.params?.type !== "tenders-awarded" &&
                  procurementType?.params?.type !== "tenders-non-awarded" ? (
                    <>
                      <div className="flex xl:float-end xl:mt-0 mt-4 md:pl-10">
                        <div className="relative rounded-md shadow-sm">
                          <input
                            onChange={(event) => {
                              setFilter(event.target.value.toLowerCase());
                            }}
                            type="search"
                            name="filter"
                            id="filter"
                            value={filter}
                            className="xl:float-end block md:w-[350px] w-full rounded-md border border-gray-200 py-2 pr-36 pl-3 placeholder:text-gray-400 text-base !outline-none"
                            placeholder={`Search ${
                              procurementType?.params?.type
                                ? procurementType?.params?.type?.replace(
                                    "-",
                                    " "
                                  )
                                : "tenders"
                            }`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center border-l">
                            <select
                              onChange={(event) => {
                                setFilterBy(event.target.value);
                              }}
                              value={filterBy}
                              id="filterBy"
                              name="filterBy"
                              className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-2 text-gray-500 text-sm !outline-none"
                            >
                              <option value="">All provinces</option>
                              <option value="Eastern Cape">Eastern Cape</option>
                              <option value="Free State">Free State</option>
                              <option value="Gauteng">Gauteng</option>
                              <option value="KwaZulu-Natal">
                                KwaZulu-Natal
                              </option>
                              <option value="Limpopo">Limpopo</option>
                              <option value="Mpumalanga">Mpumalanga</option>
                              <option value="North West">North West</option>
                              <option value="Northern Cape">
                                Northern Cape
                              </option>
                              <option value="Western Cape">Western Cape</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="clear-both"></div>
                      {filter || filterBy ? (
                        <div className="p-2 md:ml-10 md:mt-2 mt-5 bg-[#ceb596] rounded text-white text-base">
                          <strong>Search results for:</strong> {filter}{" "}
                          {filter && filterBy ? "(" + filterBy + ")" : filterBy}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>

                <div className="container mx-auto pb-16">
                  <>
                    {procurementType?.params?.type === "tenders-awarded" ||
                    procurementType?.params?.type === "tenders-non-awarded" ? (
                      <article className="md:p-10">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: procurement?.content?.rendered?.replace(
                              /<p.*?>/g,
                              "<p class='mb-5'>"
                            ),
                          }}
                        />
                      </article>
                    ) : (
                      <>
                        {procurement?.length ? (
                          <>
                            {procurement?.map((tender, i) => (
                              <div key={i}>
                                <ProcurementItem
                                  tender={tender}
                                  procurementMedia={procurementMedia}
                                  i={i}
                                />
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="md:py-4 md:px-10">
                            There are currently no{" "}
                            {procurementType?.params?.type
                              ? procurementType?.params?.type?.replace("-", " ")
                              : "tenders"}
                            .
                          </div>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
