export const WEB_ROUTES = {
  HOME: `/`,
  ABOUT: `/about`,
  MANADATE: `/about/business-mandate`,
  STRATEGY: `/about/strategy`,
  TEAM: `/team`,
  FUNDS: `/funds`,
  PROJECTS: `/projects`,
  PROJECTS_TYPE: `/projects/type`,
  IMPACT: `/impact`,
  TRAIN_SCHEDULES: `/contact-us/train-schedules`,
  ANNOUNCEMENTS: `/contact-us/announcements`,
  DIVISIONS: `/divisions`,
  DIVISION_PRASA_RAIL: `/divisions/prasa-rail`,
  DIVISION_PRASA_CRES: `/divisions/prasa-cres`,
  DIVISION_PRASA_TECH: `/divisions/prasa-tech`,
  DIVISION_AUTOPAX: `/divisions/autopax`,
  DIVISION_INTERSITE: `/divisions/intersite`,
  DIVISION_METRORAIL: `/divisions/metrorail`,
  DIVISION_SHOSHOLOZA: `/divisions/shosholoza-meyl`,
  DIVISION_CITYTOCITY: `/divisions/city-to-city`,
  DIVISION_TRANSLUX: `/divisions/translux`,
  DIVISION_PREMIER: `/divisions/premier-classe`,
  DIVISION_CITYTOCITY_TRANSLUX: `/divisions/city-to-city-and-translux`,
  RAIL_OPERATIONS: `/rail-operations`,
  BLOW_A_WHISTLE: `/contact-us/blow-a-whistle`,
  NEWS: `/news`,
  GALLERY: `/gallery`,
  NEWS_CATEGORY: `/news/category`,
  CAREERS: `/careers`,
  JOBS: `/jobs`,
  PUBLICATIONS: `/publications`,
  PROCUREMENT: `/procurement`,
  REAL_ESTATE: `/real-estate`,
  CONTACT: `/contact-us`,
  NEWSLETTER: `/mailing-list`,
  LEGAL: `/legal`,
  TERMS: `/legal/terms-of-use`,
  PRIVACY_POLICY: `/legal/privacy-policy`,
  CONFLICT_POLICY: `/legal/conflict-of-interest-policy/`,
  COOKIE_POLICY: `/legal/cookie-policy`,
  COPYRIGHT_STATEMENT: `/legal/copyright-statement`,
  POPI_PAIA: `/legal/popi-paia-manual`,
  NOT_FOUND: `/404`,
};
