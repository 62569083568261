import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* import NotFound from "pages/404"; */
import "./App.css";
import ScrollToTop from "./components/scroll-to-top";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Careers from "./pages/careers";
import JobsSingle from "./pages/jobs-single";
import Divisions from "./pages/divisions";
import Publications from "./pages/publications";
import Procurement from "./pages/procurement";
import Team from "./pages/team";
import TeamSingle from "./pages/team-single";
import News from "./pages/news";
import NewsSingle from "./pages/news-single";
import Gallery from "./pages/gallery";
import GallerySingle from "./pages/gallery-single";
import Announcements from "./pages/announcements";
import Projects from "./pages/projects";
import ProjectsSingle from "./pages/projects-single";
import RealEstate from "./pages/real-estate";
import RailOperations from "./pages/rail-operations";
import Page from "./pages/page";

import { OpenCloseContactContext } from "./contexts/contact";
import { LoaderContext } from "./contexts/loader";
import { WEB_ROUTES } from "./routes/web-routes";
import ReactGA from "react-ga4";

function App() {
  /* ReactGA.initialize("G-WSGZ8Z71NL"); */

  const [openCloseContact, setOpenCloseContact] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <OpenCloseContactContext.Provider
        value={{ openCloseContact, setOpenCloseContact }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route index exact path={WEB_ROUTES.HOME} element={<Home />} />
            <Route path={WEB_ROUTES.ABOUT} element={<About />} />
            <Route path={WEB_ROUTES.CAREERS} element={<Careers />} />
            <Route path={WEB_ROUTES.CAREERS + `/:type`} element={<Careers />} />
            <Route path={WEB_ROUTES.JOBS + `/:job`} element={<JobsSingle />} />
            <Route path={WEB_ROUTES.DIVISIONS} element={<Divisions />} />
            <Route
              path={WEB_ROUTES.DIVISIONS + `/:division`}
              element={<Divisions />}
            />
            <Route path={WEB_ROUTES.PUBLICATIONS} element={<Publications />} />
            <Route
              path={WEB_ROUTES.PUBLICATIONS + `/:type`}
              element={<Publications />}
            />
            <Route path={WEB_ROUTES.PROCUREMENT} element={<Procurement />} />
            <Route
              path={WEB_ROUTES.PROCUREMENT + `/:type/:status?`}
              element={<Procurement />}
            />

            <Route path={WEB_ROUTES.CONTACT} element={<Contact />} />

            <Route path={WEB_ROUTES.TEAM} element={<Team />} />
            <Route path={WEB_ROUTES.TEAM + `/:team`} element={<TeamSingle />} />
            <Route path={WEB_ROUTES.NEWS} element={<News />} />
            <Route
              path={WEB_ROUTES.NEWS + `/category/:category`}
              element={<News />}
            />
            <Route path={WEB_ROUTES.NEWS + `/:news`} element={<NewsSingle />} />
            <Route path={WEB_ROUTES.GALLERY} element={<Gallery />} />
            <Route
              path={WEB_ROUTES.GALLERY + `/:gallery`}
              element={<GallerySingle />}
            />
            <Route
              path={WEB_ROUTES.NEWS_CATEGORY + `/:category`}
              element={<News />}
            />
            <Route
              path={WEB_ROUTES.ANNOUNCEMENTS}
              element={<Announcements />}
            />
            <Route path={WEB_ROUTES.PROJECTS} element={<Projects />} />
            <Route
              path={WEB_ROUTES.PROJECTS + `/:project`}
              element={<ProjectsSingle />}
            />
            <Route
              path={WEB_ROUTES.PROJECTS_TYPE + `/:type`}
              element={<Projects />}
            />

            <Route path={WEB_ROUTES.REAL_ESTATE} element={<RealEstate />} />
            <Route
              path={WEB_ROUTES.REAL_ESTATE + `/:type`}
              element={<RealEstate />}
            />

            <Route
              path={WEB_ROUTES.RAIL_OPERATIONS}
              element={<RailOperations />}
            />

            {/* <Route exact path="/404" element={<NotFound />} /> */}
            <Route exact path="/:slug/*" element={<Page />} />
            <Route path="/*" element={<Home />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </OpenCloseContactContext.Provider>
    </LoaderContext.Provider>
  );
}

export default App;
