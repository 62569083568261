import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import RealEstateItem from "../components/real-estate-item";
import RealEstateOpportunitiesItem from "../components/real-estate-opportunities-item";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function RealEstate() {
  const location = useLocation();
  let realEstateType = useMatch(WEB_ROUTES.REAL_ESTATE + "/:type");

  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  let { getMetaSnippet } = useUtilities();

  const [realEstateMedia, setRealEstateMedia] = useState([]);
  const [allRealEstate, setAllRealEstate] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE("/pages", "corporate-real-estate-solutions")
      )
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (realEstateType?.params?.type === "advertising-opportunities") {
      axios
        .get(CONTENT_ROUTES.POST_PAGE("/pages", "advertising-opportunities"))
        .then((res) => {
          const pageResponse = res.data?.length ? res.data[0] : {};
          setRealEstate(pageResponse);

          //Get page media
          axios
            .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
            .then((res) => {
              setRealEstateMedia(res.data);
            });
        });
    } else if (realEstateType?.params?.type === "development-opportunities")
      getRealEstate("developments");
    else getRealEstate("real-estate");

    setFilter("");
    setFilterBy("");
  }, [realEstateType]);

  const getRealEstate = (type) => {
    //Get real estate
    axios
      .get(
        CONTENT_ROUTES.REAL_ESTATE(
          type,
          "?order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setRealEstate(res.data);
        setAllRealEstate(res.data); // Store the original list

        //Get media
        let media = [];

        if (realEstateType?.params?.type === "development-opportunities") {
          res.data?.map((item, i) => {
            if (item?.acf?.opportunity_document)
              axios
                .get(
                  CONTENT_ROUTES.MEDIA_BY_ID(item?.acf?.opportunity_document)
                )
                .then((res) => {
                  media[i] = res.data;
                  setRealEstateMedia((prevState) => [...prevState, media[i]]);
                });
            return item?.acf?.opportunity_document;
          });
        } else {
          res.data?.map((item, i) => {
            if (item?.featured_media)
              axios
                .get(CONTENT_ROUTES.MEDIA_BY_ID(item?.featured_media))
                .then((res) => {
                  media[i] = res.data;
                  setRealEstateMedia((prevState) => [...prevState, media[i]]);
                });
            return item?.featured_media;
          });
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    setRealEstate(
      filter && filterBy
        ? allRealEstate.filter(
            (item) =>
              (item?.acf?.province?.includes(filterBy) ||
                item?.acf?.region?.includes(filterBy)) &&
              item?.title?.rendered?.toLowerCase()?.includes(filter)
          )
        : filterBy
        ? allRealEstate.filter(
            (item) =>
              item?.acf?.province?.includes(filterBy) ||
              item?.acf?.region?.includes(filterBy)
          )
        : allRealEstate.filter((item) =>
            item?.title?.rendered?.toLowerCase()?.includes(filter)
          )
    );

    if (!filter && !filterBy) {
      setRealEstate(allRealEstate); // Reset to original list if input is cleared
    }
  }, [filter, filterBy, allRealEstate]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - PRASA`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main>
        <section className="bg-[#06a7e0] md:pt-16 pt-10 text-white">
          <div className="container mx-auto">
            <h1 className="pb-5 text-4xl font-semibold xl:px-0 px-8">
              {page?.title?.rendered}
            </h1>
            <div
              className="xl:px-0 px-8"
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered?.replace(
                  /<p.*?>/g,
                  "<p class='mb-5'>"
                ),
              }}
            />

            <div className="mt-10 container mx-auto flex bg-[#46515e]">
              <NavLink
                to={WEB_ROUTES.REAL_ESTATE + "/vacant-premises"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  realEstateType?.params?.type === undefined ||
                  realEstateType?.params?.type === null ||
                  location.pathname.indexOf("vacant-premises") !== -1
                    ? "bg-[#626873]"
                    : ""
                }`}
              >
                To&nbsp;Let
              </NavLink>
              <NavLink
                to={WEB_ROUTES.REAL_ESTATE + "/development-opportunities"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("development-opportunities") !==
                    -1 && "bg-[#626873]"
                }`}
              >
                Development Opportunities
              </NavLink>
              <NavLink
                to={WEB_ROUTES.REAL_ESTATE + "/advertising-opportunities"}
                className={`md:px-5 px-4 py-4 cursor-pointer normal-case inline-block hover:bg-[#626873] hover:text-white ${
                  location.pathname.indexOf("advertising-opportunities") !==
                    -1 && "bg-[#626873]"
                }`}
              >
                Advertising Opportunities
              </NavLink>
            </div>
          </div>
        </section>

        {realEstateType?.params?.type === "advertising-opportunities" ? (
          <section className="relative">
            <div
              className="hidden md:block -z-10 absolute top-0 left-0 h-[650px] w-[33%] bg-no-repeat xl:bg-[left_-200px_top_0px] bg-[center_top_0px] bg-[length:auto_100%]"
              style={{
                backgroundImage: `url(${
                  realEstateMedia?.find(
                    (media) => media?.id === realEstate?.featured_media
                  )?.source_url
                })`,
              }}
            ></div>

            <div className="container mx-auto">
              <div className="md:flex md:justify-end">
                <div className="md:w-9/12 xl:pr-10 px-0 bg-white min-h-96">
                  <div className="md:p-10 py-10 px-8">
                    <h2 className="mb-10 text-3xl font-medium text-neutral-700">
                      {realEstate?.title?.rendered}
                    </h2>
                    <article>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: realEstate?.content?.rendered?.replace(
                            /<p.*?>/g,
                            "<p class='mb-5'>"
                          ),
                        }}
                      />
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="relative">
            <div className="container mx-auto xl:px-0 px-8">
              <div className="mb-10">
                <div className="py-10">
                  <h2 className="text-3xl font-medium md:pr-10 text-neutral-700 xl:float-start first-letter:uppercase capitalize">
                    {!realEstateType?.params?.type ||
                    realEstateType?.params?.type === "vacant-premises"
                      ? "Premises To Let"
                      : realEstateType?.params?.type?.replace("-", " ")}{" "}
                    ({realEstate?.length})
                  </h2>

                  <>
                    <div className="flex xl:float-end xl:mt-0 mt-4">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          onChange={(event) => {
                            setFilter(event.target.value.toLowerCase());
                          }}
                          type="search"
                          name="filter"
                          id="filter"
                          value={filter}
                          className=" md:float-end block md:w-[420px] w-full rounded-md border border-gray-200 py-2 pr-36 pl-3 placeholder:text-gray-400 text-base !outline-none"
                          placeholder={`Search ${
                            !realEstateType?.params?.type ||
                            realEstateType?.params?.type === "vacant-premises"
                              ? "premises to let"
                              : realEstateType?.params?.type?.replace("-", " ")
                          }`}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center border-l">
                          <select
                            onChange={(event) => {
                              console.log(event.target.value);
                              setFilterBy(event.target.value);
                            }}
                            id="filterBy"
                            name="filterBy"
                            value={filterBy}
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-2 text-gray-500 text-sm !outline-none"
                          >
                            <option value="">All provinces</option>
                            <option value="Eastern Cape">Eastern Cape</option>
                            <option value="Free State">Free State</option>
                            <option value="Gauteng">Gauteng</option>
                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                            <option value="Limpopo">Limpopo</option>
                            <option value="Mpumalanga">Mpumalanga</option>
                            <option value="North West">North West</option>
                            <option value="Northern Cape">Northern Cape</option>
                            <option value="Western Cape">Western Cape</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="clear-both"></div>
                    {filter || filterBy ? (
                      <div className="p-2 md:mt-2 mt-5 bg-[#ceb596] rounded text-white text-base">
                        <strong>Search results for:</strong> {filter}{" "}
                        {filter && filterBy ? "(" + filterBy + ")" : filterBy}
                      </div>
                    ) : null}
                  </>
                </div>
                <div>
                  {realEstate?.length ? (
                    <>
                      {realEstateType?.params?.type ===
                      "development-opportunities" ? (
                        <div className="container mx-auto pb-16">
                          {realEstate?.map((item, i) => (
                            <div key={i}>
                              <RealEstateOpportunitiesItem
                                item={item}
                                itemMedia={realEstateMedia}
                                i={i}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 ">
                          {realEstate?.map((item, i) => (
                            <Fragment key={i}>
                              <RealEstateItem
                                item={item}
                                itemMedia={
                                  realEstateMedia?.find(
                                    (media) =>
                                      media?.id === item?.featured_media
                                  )?.source_url
                                }
                              />
                            </Fragment>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="py-4">
                      There are currently no{" "}
                      {!realEstateType?.params?.type ||
                      realEstateType?.params?.type === "vacant-premises"
                        ? "premises to let"
                        : realEstateType?.params?.type?.replace("-", " ")}
                      .
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      <Footer />
    </>
  );
}
